import React from "react";

const Franchise = () => {
  return (
    <>
      <div className="col-md-8 mx-auto">
        <h2>
          Depuis 2005 la Paris Saint-Germain Academy déploie ses programmes en
          France et à l’international. Ces écoles sont ouvertes en franchise,
          avec un partenaire local dans chaque pays.
        </h2>
        <p className="fs-17 my-4">
          Rejoindre le projet Paris Saint Germain Academy est une opportunité
          unique de proposer à l’ensemble des enfants sur votre territoire
          d’intégrer une académie de renom, ouverte à tous et dispensant un
          programme technique reconnu sur le plan international.
        </p>
        <p className="fs-17 my-4">
          Elaboré et supervisé par le Directeur Technique de la Paris
          Saint-Germain Academy, le programme sportif respecte la méthodologie
          et philosophie de jeu du club tout en s’adaptant au niveau de chacun.
        </p>
        <p className="fs-17 my-4">
          Si vous souhaitez partager avec nos équipes votre projet, merci de
          compléter le formulaire ci-dessous, nous ne manquerons pas de vous
          recontacter dès que possible.{" "}
        </p>
        <form className="py-5">
          <h4>Vos coordonnées</h4>
          <div className="py-3">
            <label htmlFor="">CIVILITÉ</label>
            <select className="form-control rounded-0 shadow-none">
              <option value="">Monsieur</option>
              <option value="">Madame</option>
            </select>
          </div>
          <div className="py-3">
            <label htmlFor="">
              PRENOM <span className="text-blue fs-5">*</span>
            </label>
            <input type="text" className="form-control rounded-0 shadow-none" />
          </div>
          <div className="py-3">
            <label htmlFor="">
              NOM <span className="text-blue fs-5">*</span>
            </label>
            <input type="text" className="form-control rounded-0 shadow-none" />
          </div>
          <div className="py-3">
            <label htmlFor="">
              ADRESSE EMAIL
              <span className="text-blue fs-5">*</span>
            </label>
            <input type="text" className="form-control rounded-0 shadow-none" />
          </div>
          <div className="py-3">
            <label htmlFor="">
              ADRESSE POSTALE
              <span className="text-blue fs-5">*</span>
            </label>
            <input type="text" className="form-control rounded-0 shadow-none" />
          </div>
          <div className="py-3">
            <h3>Votre projet</h3>
            <p className="fw-bold">TYPE DE PROJET *</p>
            <div class="form-check">
              <input
                class="form-check-input shadow-none rounded-0"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label">Football</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input shadow-none rounded-0"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />

              <label class="form-check-label">Football</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input shadow-none rounded-0"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />

              <label class="form-check-label">Football</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input shadow-none rounded-0"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />

              <label class="form-check-label">Football</label>
            </div>
            <p>Vous pouvez cocher plusieurs options</p>
          </div>
          <div className="py-3">
            <label htmlFor="">
              NOM DE VOTRE SOCIÉTÉ
              <span className="text-blue fs-5">*</span>
            </label>
            <input type="text" className="form-control rounded-0 shadow-none" />
          </div>
          <div className="py-3">
            <label htmlFor="">
              LOCALISATION DE VOTRE PROJET
              <span className="text-blue fs-5">*</span>
            </label>
            <input type="text" className="form-control rounded-0 shadow-none" />
          </div>
          <div className="py-3">
            <label htmlFor="">
              POURQUOI SOUHAITEZ-VOUS DEVENIR PARTENAIRE ?
              <span className="text-blue fs-5">*</span>
            </label>
            <input type="text" className="form-control rounded-0 shadow-none" />
          </div>
          <div className="py-3">
            <label htmlFor="">
              QUELLE EST VOTRE EXPÉRIENCE DANS LA GESTION D'ÉCOLES SPORTIVES ?
            </label>
            <input type="text" className="form-control rounded-0 shadow-none" />
          </div>
          <div className="py-3">
            <label htmlFor="">
              DÉCRIVEZ VOTRE PROJET
              <span className="text-blue fs-5">*</span>
            </label>
            <input type="text" className="form-control rounded-0 shadow-none" />
          </div>
          <button className="btn rounded-0 text-white py-2 bg-blue px-4">
            VALIDER
          </button>
        </form>
      </div>
    </>
  );
};

export default Franchise;
