import React from "react";
// import img4 from "../../../images/jobs-famille.jpg";
// import facory1 from "../../../images/factory-01.jpg";
// import facory2 from "../../../images/factory-02.jpg";
// import facory3 from "../../../images/factory-03.jpg";
// import facory4 from "../../../images/factory-04.jpg";
// import facory5 from "../../../images/factory-05.jpg";
// import facory6 from "../../../images/factory-06.jpg";
// import facory7 from "../../../images/factory-07.jpg";
// import parc1 from "../../../images/parc-01.jpg";
// import parc2 from "../../../images/parc-02.jpg";
import Navbar from "../../../components/Navbar";
import {
  FaBuilding,
  FaFootballBall,
  FaGlobe,
  FaShoppingBag,
  FaStar,
} from "react-icons/fa";

const NotreFamille = () => {
  return (
    <>
      <Navbar />
      <div className=" position-relative">
        <img
          src="/images/jobs-famille.jpg"
          alt=""
          className="object-fit-cover w-100"
          style={{ height: "25rem" }}
        />
        <h2 className="position-absolute fw-bold start-50 bottom-50 text-light">
          Notre Famille
        </h2>
      </div>
      <div className="col-md-7 mx-auto py-5">
        <h3>
          Rejoindre le Paris Saint-Germain, c’est faire partie d’une grande
          famille.
        </h3>
        <p className="fs-17">
          Toute l’année, le Club prend soin de ses collaborateurs et travaille
          pour apporter des conditions de travail optimales sur l'ensemble de
          ses 10 sites.
        </p>
        <div className="row">
          <div className="col-md-6 my-2">
            <div className="bg-light text-center p-4">
              <FaStar className="display-3 text-blue" />
              <h4 className="py-3 mb-0 text-blue fw-bold">
                LE PARC DES PRINCES
              </h4>
              <p className="fs-17">
                Notre siège social dans le XVIème arrondissement.
              </p>
            </div>
          </div>
          <div className="col-md-6 my-2">
            <div className="bg-light text-center p-4">
              <FaBuilding className="display-3 text-blue" />
              <h4 className="py-3 mb-0 text-blue fw-bold">LA FACTORY</h4>
              <p className="fs-17">
                Notre siège administratif à Boulogne-Billancourt.
              </p>
            </div>
          </div>
          <div className="col-md-6 my-2">
            <div className="bg-light text-center p-4">
              <FaShoppingBag className="display-3 text-blue" />
              <h4 className="py-3 mb-0 text-blue fw-bold">NOS 3 BOUTIQUES</h4>
              <p className="fs-17">
                Le Mégastore au Parc des Princes, la boutique des
                Champs-Elysées, la boutique des Galeries Lafayette Haussmann.
              </p>
            </div>
          </div>
          <div className="col-md-6 my-2">
            <div className="bg-light text-center p-4">
              <FaGlobe className="display-3 text-blue" />
              <h4 className="py-3 mb-0 text-blue fw-bold">
                NOS BUREAUX INTERNATIONAUX
              </h4>
              <p className="fs-17">A Doha et Singapour.</p>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="bg-light text-center p-4">
            <FaFootballBall className="display-3 text-blue" />
            <h4 className="py-3 mb-0 text-blue fw-bold">NOS SITES SPORTIFS</h4>
            <p className="fs-17">
              Le centre OOREDOO (centre d’entraînement de l'équipe masculine de
              football), le centre de formation, le centre Bougival (centre
              d’entraînement de l'équipe féminine de football), le centre de
              préformation, et le stade Pierre de Coubertin (salle
              d'entraînement de l'équipe de handball).{" "}
            </p>
          </div>
        </div>
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="/images/factory-01.jpg" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item active">
              <img src="/images/factory-02.jpg" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item active">
              <img src="/images/factory-03.jpg" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src="/images/factory-04.jpg" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src="/images/factory-05.jpg" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src="/images/factory-06.jpg" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src="/images/factory-07.jpg" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src="/images/parc-01.jpg" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src="/images/parc-02.jpg" class="d-block w-100" alt="..." />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <h3 className="py-3">Des événements toute l'année</h3>
        <p className="fs-17">
          La saison au Paris Saint-Germain est également rythmée par de nombreux
          événements organisés en interne :
        </p>
        <ul>
          <li className="fs-17">les vœux de la Direction ;</li>
          <li className="fs-17">
            les "PSG TALKS" – Coffee Learn autour d’un sujet d’actualité avec
            des internes et externes au Club ;
          </li>
          <li className="fs-17">les afterworks ;</li>
          <li className="fs-17">les tournois sportifs ;</li>
          <li className="fs-17">le noël des familles au Parc des Princes.</li>
        </ul>
        <video className="w-100" controls autoplay loop muted>
          <source src="/images/video-banner.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
};

export default NotreFamille;
