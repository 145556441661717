import React from "react";
// import img1 from "../../../images/stages.jpg";
// import img2 from "../../../images/methodologie.jpg";
// import img3 from "../../../images/encadrement.jpg";

const Judo = () => {
  const Data = [
    {
      id: "1",
      img: "/images/stages.jpg",
      // img: img1,
      title: "Un programme à l’année et des stages ponctuels",
      para: "Ouverte aux filles et garçons de tous niveaux, la Paris Saint-Germain Academy Judo a pour but d’accompagner de jeunes pratiquants dans leur développement technique et personnel en essayant toujours de tirer le meilleur des valeurs du judo et du Paris Saint-Germain.",
    },
    {
      id: "2",
      img: "/images/methodologie.jpg",
      // img: img2,
      title: "Une méthodologie et un programme unique",
      para: "Le savoir-faire technique du club, doublé d’un programme motivationnel unique conçu par le Paris Saint-Germain, permet aux enfants de progresser et s’épanouir tout au long de l’année.",
    },
    {
      id: "3",
      img: "/images/encadrement.jpg",
      // img: img3,
      title: "Des infrastructures et un encadrement de haut niveau",
      para: "Les séances sont dispensées par des entraineurs compétents et expérimentés, tous ceintures noires. Les jeunes judokas sont accueillis dans des infrastructures haut de gamme et professionnelles, permettant une pratique du judo sécurisée et dans les meilleures conditions possibles.",
    },
  ];
  return (
    <>
      <div className="col-md-7 mx-auto">
        <h4 className="text-center py-5">
          Depuis 2023, le club décline le concept Paris Saint-Germain Academy au
          domaine du Judo. Implantée dans différents pays, la Paris
          Saint-Germain Academy Judo permet à de jeunes pratiquants de Judo de
          progresser en profitant du savoir-faire du club.
        </h4>
      </div>
      <div className="py-5">
        <div className="container bg-white py-5">
          {Data.map((val, index) => (
            <div
              key={index}
              className={`row align-items-center ${
                index % 2 !== 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="col-md-6 px-5 py-5">
                <h1 className="text-blue fw-bold dispaly-4">{val.title}</h1>
                <p className="fs-17">{val.para}</p>
              </div>
              <div className="col-md-6 py-5">
                <img src={val.img} className="w-100" alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Judo;
