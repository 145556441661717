import React from "react";

const Effectif = () => {
  return (
    <>
      <div className="mx-5 py-5">
        <h4>Gardiens</h4>
        <div className="row">
          <div class="col-md-4 cursor-pointer mb-3">
            <div className="layerMain">
              <div
                class="effectif_cardImg"
                style={{
                  backgroundImage:
                    'linear-gradient(rgba(0, 0, 0, 0.1), rgba(32, 31, 31, 0.9)), url("/images/cards-img.png")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <p class="text-light display-4 p-4">40</p>
                <div>
                  <p class="text-light mb-1 ps-4 layerTextHide">GARDIEN</p>
                  <h1 class="text-light ps-4 mb-0 ">Alexandre Letellier</h1>
                  <hr class="text-light m-0" />
                  <h6 class="py-3 ps-4 text-light" style={{ zIndex: "3" }}>
                    FICHE DU JOUEUR
                  </h6>
                </div>
                <div class="layer d-flex justify-content-between">
                  <div>
                    <div class="mb-5">
                      <h1 class="fw-bold mb-0 text-blue text-center">3</h1>
                      <h6 class="mb-0 text-center">Matchs joués</h6>
                    </div>
                    <div>
                      <h1 class="fw-bold mb-0 text-blue text-center">258</h1>
                      <h6 class="mb-0 text-center">Minutes jouées</h6>
                    </div>
                  </div>
                  <div>
                    <div class="mb-4">
                      <h1 class="fw-bold mb-0 text-blue text-center">1</h1>
                      <h6 class="mb-0 text-center">Clean sheets</h6>
                    </div>
                    <div>
                      <h1 class="fw-bold mb-0 text-blue text-center">12</h1>
                      <h6 class="mb-0 text-center">Arrets</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 cursor-pointer mb-3">
            <div className="layerMain">
              <div
                class="effectif_cardImg"
                style={{
                  backgroundImage:
                    'linear-gradient(rgba(0, 0, 0, 0.1), rgba(32, 31, 31, 0.9)), url("/images/cards-img.png")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <p class="text-light display-4 p-4">40</p>
                <div>
                  <p class="text-light mb-1 ps-4 layerTextHide">GARDIEN</p>
                  <h1 class="text-light ps-4 mb-0 ">Alexandre Letellier</h1>
                  <hr class="text-light m-0" />
                  <h6 class="py-3 ps-4 text-light" style={{ zIndex: "3" }}>
                    FICHE DU JOUEUR
                  </h6>
                </div>
                <div class="layer d-flex justify-content-between">
                  <div>
                    <div class="mb-5">
                      <h1 class="fw-bold mb-0 text-blue text-center">3</h1>
                      <h6 class="mb-0 text-center">Matchs joués</h6>
                    </div>
                    <div>
                      <h1 class="fw-bold mb-0 text-blue text-center">258</h1>
                      <h6 class="mb-0 text-center">Minutes jouées</h6>
                    </div>
                  </div>
                  <div>
                    <div class="mb-4">
                      <h1 class="fw-bold mb-0 text-blue text-center">1</h1>
                      <h6 class="mb-0 text-center">Clean sheets</h6>
                    </div>
                    <div>
                      <h1 class="fw-bold mb-0 text-blue text-center">12</h1>
                      <h6 class="mb-0 text-center">Arrets</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 cursor-pointer mb-3">
            <div className="layerMain">
              <div
                class="effectif_cardImg"
                style={{
                  backgroundImage:
                    'linear-gradient(rgba(0, 0, 0, 0.1), rgba(32, 31, 31, 0.9)), url("/images/cards-img.png")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <p class="text-light display-4 p-4">40</p>
                <div>
                  <p class="text-light mb-1 ps-4 layerTextHide">GARDIEN</p>
                  <h1 class="text-light ps-4 mb-0 ">Alexandre Letellier</h1>
                  <hr class="text-light m-0" />
                  <h6 class="py-3 ps-4 text-light" style={{ zIndex: "3" }}>
                    FICHE DU JOUEUR
                  </h6>
                </div>
                <div class="layer d-flex justify-content-between">
                  <div>
                    <div class="mb-5">
                      <h1 class="fw-bold mb-0 text-blue text-center">3</h1>
                      <h6 class="mb-0 text-center">Matchs joués</h6>
                    </div>
                    <div>
                      <h1 class="fw-bold mb-0 text-blue text-center">258</h1>
                      <h6 class="mb-0 text-center">Minutes jouées</h6>
                    </div>
                  </div>
                  <div>
                    <div class="mb-4">
                      <h1 class="fw-bold mb-0 text-blue text-center">1</h1>
                      <h6 class="mb-0 text-center">Clean sheets</h6>
                    </div>
                    <div>
                      <h1 class="fw-bold mb-0 text-blue text-center">12</h1>
                      <h6 class="mb-0 text-center">Arrets</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 cursor-pointer mb-3">
            <div className="layerMain">
              <div
                class="effectif_cardImg"
                style={{
                  backgroundImage:
                    'linear-gradient(rgba(0, 0, 0, 0.1), rgba(32, 31, 31, 0.9)), url("/images/cards-img.png")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <p class="text-light display-4 p-4">40</p>
                <div>
                  <p class="text-light mb-1 ps-4 layerTextHide">GARDIEN</p>
                  <h1 class="text-light ps-4 mb-0 ">Alexandre Letellier</h1>
                  <hr class="text-light m-0" />
                  <h6 class="py-3 ps-4 text-light" style={{ zIndex: "3" }}>
                    FICHE DU JOUEUR
                  </h6>
                </div>
                <div class="layer d-flex justify-content-between">
                  <div>
                    <div class="mb-5">
                      <h1 class="fw-bold mb-0 text-blue text-center">3</h1>
                      <h6 class="mb-0 text-center">Matchs joués</h6>
                    </div>
                    <div>
                      <h1 class="fw-bold mb-0 text-blue text-center">258</h1>
                      <h6 class="mb-0 text-center">Minutes jouées</h6>
                    </div>
                  </div>
                  <div>
                    <div class="mb-4">
                      <h1 class="fw-bold mb-0 text-blue text-center">1</h1>
                      <h6 class="mb-0 text-center">Clean sheets</h6>
                    </div>
                    <div>
                      <h1 class="fw-bold mb-0 text-blue text-center">12</h1>
                      <h6 class="mb-0 text-center">Arrets</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 cursor-pointer mb-3">
            <div className="layerMain">
              <div
                class="effectif_cardImg"
                style={{
                  backgroundImage:
                    'linear-gradient(rgba(0, 0, 0, 0.1), rgba(32, 31, 31, 0.9)), url("/images/cards-img.png")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <p class="text-light display-4 p-4">40</p>
                <div>
                  <p class="text-light mb-1 ps-4 layerTextHide">GARDIEN</p>
                  <h1 class="text-light ps-4 mb-0 ">Alexandre Letellier</h1>
                  <hr class="text-light m-0" />
                  <h6 class="py-3 ps-4 text-light" style={{ zIndex: "3" }}>
                    FICHE DU JOUEUR
                  </h6>
                </div>
                <div class="layer d-flex justify-content-between">
                  <div>
                    <div class="mb-5">
                      <h1 class="fw-bold mb-0 text-blue text-center">3</h1>
                      <h6 class="mb-0 text-center">Matchs joués</h6>
                    </div>
                    <div>
                      <h1 class="fw-bold mb-0 text-blue text-center">258</h1>
                      <h6 class="mb-0 text-center">Minutes jouées</h6>
                    </div>
                  </div>
                  <div>
                    <div class="mb-4">
                      <h1 class="fw-bold mb-0 text-blue text-center">1</h1>
                      <h6 class="mb-0 text-center">Clean sheets</h6>
                    </div>
                    <div>
                      <h1 class="fw-bold mb-0 text-blue text-center">12</h1>
                      <h6 class="mb-0 text-center">Arrets</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 cursor-pointer mb-3">
            <div className="layerMain">
              <div
                class="effectif_cardImg"
                style={{
                  backgroundImage:
                    'linear-gradient(rgba(0, 0, 0, 0.1), rgba(32, 31, 31, 0.9)), url("/images/cards-img.png")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <p class="text-light display-4 p-4">40</p>
                <div>
                  <p class="text-light mb-1 ps-4 layerTextHide">GARDIEN</p>
                  <h1 class="text-light ps-4 mb-0 ">Alexandre Letellier</h1>
                  <hr class="text-light m-0" />
                  <h6 class="py-3 ps-4 text-light" style={{ zIndex: "3" }}>
                    FICHE DU JOUEUR
                  </h6>
                </div>
                <div class="layer d-flex justify-content-between">
                  <div>
                    <div class="mb-5">
                      <h1 class="fw-bold mb-0 text-blue text-center">3</h1>
                      <h6 class="mb-0 text-center">Matchs joués</h6>
                    </div>
                    <div>
                      <h1 class="fw-bold mb-0 text-blue text-center">258</h1>
                      <h6 class="mb-0 text-center">Minutes jouées</h6>
                    </div>
                  </div>
                  <div>
                    <div class="mb-4">
                      <h1 class="fw-bold mb-0 text-blue text-center">1</h1>
                      <h6 class="mb-0 text-center">Clean sheets</h6>
                    </div>
                    <div>
                      <h1 class="fw-bold mb-0 text-blue text-center">12</h1>
                      <h6 class="mb-0 text-center">Arrets</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Effectif;
