import React from "react";
// import img1 from "../../../images/academie-handball-stages.jpg";
// import img2 from "../../../images/academie-handball-programme.jpg";
// import img3 from "../../../images/matchgr2-8.png";
// import img4 from "../../../images/psg-academy.jpg";

const Handball = () => {
  const Data = [
    {
      id: "1",
      img: "/images/academie-handball-stages.jpg",
      // img: img1,
      title: "Les stages PSG Academy Handball en France",
      para: "Pendant les vacances scolaires, les stages de la PSG Academy Handball accueillent filles et garçons âgés de 10 à 17 ans pour des stages intensifs qui visent à la progression technique mais aussi à l’épanouissement personnel des jeunes pratiquants.Au programme : entrainement individuels et collectifs, matchs, analyse vidéo, sensibilisation à la récupération et la nutrition ; et rencontre avec leurs idoles !",
    },
    {
      id: "2",
      img: "/images/academie-handball-programme.jpg",
      // img: img2,
      title: "Inscriptions",
      para: "Retrouvez toutes les informations et les dates des stages Paris Saint-Germain Academy Handball en cliquant sur le lien ci-dessous.>> Pour toute autre question, vous pouvez envoyer un mail à contact@psghandasso.com ou psgacademyhandball@psg.fr.      >> Retrouvez-nous sur notre page Facebook Paris Saint-Germain Academy Handball.      >> Retrouvez nous sur notre page Instagram psgacademyhandball.",
    },
    {
      id: "3",
      img: "/images/matchgr2-8.png",
      // img: img3,
      title: "Un programme à l’année au Qatar",
      para: "La Paris Saint-Germain Academy Handball est également présente au Qatar avec un programme à l’année. L’occasion de plonger au cœur de la méthodologie d’entrainement PSG Handball pour une saison complète auprès de coachs certifiés et dans des infrastructures de haut niveau",
    },
    {
      id: "4",
      img: "/images/psg-academy.jpg",
      // img: img4,
      title: "Inscriptions",
      para: "Retrouvez toutes les informations sur le programme à l’année PSG Academy Handball en cliquant sur le lien ci-dessous :",
    },
  ];
  return (
    <>
      <div className="col-md-7 mx-auto">
        <h4 className="text-center py-5">
          Implantée en France et au Qatar, la Paris Saint-Germain Academy
          Handball ouvre ses portes aux jeunes passionnés de Handball désireux
          de se perfectionner tout en partageant les valeurs du club.
        </h4>
      </div>
      <div className="py-5">
        <div className="container bg-white py-5">
          {Data.map((val, index) => (
            <div
              key={index}
              className={`row align-items-center ${
                index % 2 !== 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="col-md-6 px-5 py-5">
                <h1 className="text-blue fw-bold dispaly-4">{val.title}</h1>
                <p className="fs-17">{val.para}</p>
              </div>
              <div className="col-md-6 py-5">
                <img src={val.img} className="w-100" alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Handball;
