import React, { useState } from "react";
import Navbar from "../../components/Navbar";
// import img from "../../images/foundation-header-image.jpg";
import Actualite from "../../components/fondation/Actualite";
import Presentation from "../../components/academy/Presentation";
import AutresSports from "../../components/academy/autresSport/AutresSports";
import Football from "../../components/academy/football/Football";
import Franchise from "../../components/academy/Franchise";

const Academy = () => {
  const [activeButton, setActiveButton] = useState("presentation");
  const [showStaff, setShowStaff] = useState(true);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setShowStaff(buttonName === "presentation");
  };

  return (
    <>
      <Navbar />
      <img src="/images/foundation-header-image.jpg" className="w-100" alt="" />
      {/* --- */}
      <div className="d-flex justify-content-center py-5">
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "presentation" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("presentation")}
        >
          PRÉSENTATION
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "Actualite" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("Actualite")}
        >
          ACTUALITÉ
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "football" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("football")}
        >
          FOOTBALL
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "autres-sports" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("autres-sports")}
        >
          AUTRES SPORTS
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "franchise" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("franchise")}
        >
          FRANCHISE
        </button>
      </div>
      {showStaff && <Presentation />}
      {activeButton === "Actualite" && <Actualite />}
      {activeButton === "autres-sports" && <AutresSports />}
      {activeButton === "football" && <Football />}
      {activeButton === "franchise" && <Franchise />}
    </>
  );
};

export default Academy;
