import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import VideoPlayer from "react-video-js-player";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
// import img from "../../images/banner.webp";
// import montpellier from "../../images/montpellier.png";
// import men from "../../images/men.webp";
// import women from "../../images/women.webp";
// import vid from "../../video-banner.mp4";
import { FaPlay } from "react-icons/fa";
// import ReactPlayer from "react-player";
// import videoFileImg from "../../images/banner.webp";
import videoData from "../../pages/medias/VideoData";
import { Link } from "react-router-dom";

export default function App() {
  return (
    <>
      <div className="my-5 ms-5">
        <h1 className="fw-bold">Latest Videos</h1>

        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper "
        >
          {videoData.map((val, index) => (
            <SwiperSlide className="cursor-pointer">
              {/* <div className="col-md-3 mb-3" key={index}> */}
              <Link to={`/videoDetail/${val.id}`}>
                <div className="shadow h-100">
                  <div style={{ position: "relative" }}>
                    <div className="image-container">
                      <video className="w-100 zoom-img" poster={val.img}>
                        <source src={val.video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <div
                        style={{
                          position: "absolute",
                          left: "5px",
                          bottom: "12px",
                        }}
                        className="bg-blue py-2 px-3"
                      >
                        <FaPlay className="text-light" />
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="d-flex gap-2">
                      <p className="fw-bold mb-0">{val.category}</p>
                      <p className="mb-0">|</p>
                      <p className="mb-0">{val.date}</p>
                    </div>
                    {/* <p className="fs-17 fw-bold">{val.title}</p> */}
                  </div>
                </div>
              </Link>
              {/* </div> */}

              {/* <div style={{ position: "relative" }}>
              <video
                className="w-100"
                controls
                autoplay
                loop
                muted
                poster={videoFileImg}
              >
                <source src="video-banner.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div
                style={{ position: "absolute", left: "5px", bottom: "5px" }}
                className="bg-blue py-2 px-3"
              >
                {" "}
                <FaPlay className="text-light" />
              </div>
            </div>
            <h4 className="mt-4">Lorem ipsum dolor sit amet consectetur</h4> */}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* -------------- */}
      <div className="container py-5">
        <div>
          <h1 className="">Mens</h1>
          <div className="row">
            <div className="col-md-6">
              <div className="bg-white py-4">
                <h5 className="text-center">WEDNESDAY MARCH 20TH 2024</h5>
                <p className="text-center">
                  WOMEN'S CHAMPIONS LEAGUE - QUARTER-FINAL
                </p>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <p className="mb-0">MONTPELLIER</p>
                  <img src="/images/montpellier.png" width={60} alt="" />
                  <h5 className="p-3 bg-gray">1:00 AM</h5>
                  <img src="/images/montpellier.png" width={60} alt="" />
                  <p className="mb-0">MONTPELLIER</p>
                </div>
                <div className="button btn bg-blue text-light rounded-0 py-2 d-flex justify-content-center w-25 mx-auto mt-3">
                  Match Center
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-white py-4">
                <h5 className="text-center">WEDNESDAY MARCH 20TH 2024</h5>
                <p className="text-center">
                  WOMEN'S CHAMPIONS LEAGUE - QUARTER-FINAL
                </p>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <p className="mb-0">MONTPELLIER</p>
                  <img src="/images/montpellier.png" width={60} alt="" />
                  <h5 className="p-3 bg-gray">1:00 AM</h5>
                  <img src="/images/montpellier.png" width={60} alt="" />
                  <p className="mb-0">MONTPELLIER</p>
                </div>
                <div className="button btn bg-blue text-light rounded-0 py-2 d-flex justify-content-center w-25 mx-auto mt-3">
                  Match Center
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <h1 className="">Women</h1>
          <div className="row">
            <div className="col-md-6">
              <div className="bg-white py-4">
                <h5 className="text-center">WEDNESDAY MARCH 20TH 2024</h5>
                <p className="text-center">
                  WOMEN'S CHAMPIONS LEAGUE - QUARTER-FINAL
                </p>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <p className="mb-0">MONTPELLIER</p>
                  <img src="/images/montpellier.png" width={60} alt="" />
                  <h5 className="p-3 bg-gray">1:00 AM</h5>
                  <img src="/images/montpellier.png" width={60} alt="" />
                  <p className="mb-0">MONTPELLIER</p>
                </div>
                <div className="button btn bg-blue text-light rounded-0 py-2 d-flex justify-content-center w-25 mx-auto mt-3">
                  Match Center
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-white py-4">
                <h5 className="text-center">WEDNESDAY MARCH 20TH 2024</h5>
                <p className="text-center">
                  WOMEN'S CHAMPIONS LEAGUE - QUARTER-FINAL
                </p>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <p className="mb-0">MONTPELLIER</p>
                  <img src="/images/montpellier.png" width={60} alt="" />
                  <h5 className="p-3 bg-gray">1:00 AM</h5>
                  <img src="/images/montpellier.png" width={60} alt="" />
                  <p className="mb-0">MONTPELLIER</p>
                </div>
                <div className="button btn bg-blue text-light rounded-0 py-2 d-flex justify-content-center w-25 mx-auto mt-3">
                  Match Center
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -------------- */}

      {/* <h1 className="text-center fw-bold mt-5 mb-3">Our Key Players</h1> */}
      {/* <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div
              className="bg-white playersBox"
              style={{ position: "relative" }}
            >
              <img
                src={men}
                className="w-100"
                alt=""
                style={{ height: "470px" }}
              />
              <h1
                className="mb-0 mt-2 pt-3 display-5"
                style={{
                  writingMode: "vertical-lr",
                  position: "absolute",
                  top: "0",
                }}
              >
                MEN
              </h1>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <div
              className="bg-white playersBox"
              style={{ position: "relative" }}
            >
              <img
                src={women}
                className="w-100"
                alt=""
                style={{ height: "470px" }}
              />
              <h1
                className="mb-0 mt-2 pt-3 display-5"
                style={{
                  writingMode: "vertical-lr",
                  position: "absolute",
                  top: "0",
                }}
              >
                WOMEN
              </h1>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
