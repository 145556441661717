import React from "react";
// import img1 from "../../images/artcurial.png";
// import img2 from "../../images/avenirapei.png";
// import img3 from "../../images/clamart.png";
// import img4 from "../../images/colombes2324.png";
// import img5 from "../../images/solidarites-etudiantes.png";
// import img6 from "../../images/ecotable.png";
// import img7 from "../../images/efdn.png";
// import img8 from "../../images/footballismore.png";
// import img9 from "../../images/manteslajolie.png";
// import img10 from "../../images/necker.png";
// import img11 from "../../images/rivp.png";
// import img12 from "../../images/studhelp.png";
// import img13 from "../../images/unapei.png";
// import img14 from "../../images/vitry.png";
// import img15 from "../../images/banijay.png";
// import img16 from "../../images/bgc.png";
// import img17 from "../../images/inocapgestion.png";
// import img18 from "../../images/jhb.png";
// import img19 from "../../images/momense.png";

const Partenaires = () => {
  const imgsData = [
    {
      id: "1",
      img: "/images/artcurial.png",
      // img: img1,
    },
    {
      id: "2",
      img: "/images/avenirapei.png",
      // img: img2,
    },
    {
      id: "3",
      img: "/images/clamart.png",
      // img: img3,
    },
    {
      id: "4",
      img: "/images/colombes2324.png",
      // img: img4,
    },
    {
      id: "5",
      img: "/images/solidarites-etudiantes.png",
      // img: img5,
    },
    {
      id: "6",
      img: "/images/ecotable.png",
      // img: img6,
    },
    {
      id: "7",
      img: "/images/efdn.png",
      // img: img7,
    },
    {
      id: "8",
      img: "/images/footballismore.png",
      // img: img8,
    },
    {
      id: "9",
      img: "/images/manteslajolie.png",
      // img: img9,
    },
    {
      id: "10",
      img: "/images/necker.png",
      // img: img10,
    },
    {
      id: "11",
      img: "/images/rivp.png",
      // img: img11,
    },
    {
      id: "12",
      img: "/images/studhelp.png",
      // img: img12,
    },
    {
      id: "13",
      img: "/images/unapei.png",
      // img: img13,
    },
    {
      id: "14",
      img: "/images/vitry.png",
      // img: img14,
    },
    {
      id: "15",
      img: "/images/banijay.png",
      // img: img15,
    },
    {
      id: "16",
      img: "/images/bgc.png",
      // img: img16,
    },
    {
      id: "17",
      img: "/images/inocapgestion.png",
      // img: img17,
    },
    {
      id: "18",
      img: "/images/jhb.png",
      // img: img18,
    },
    {
      id: "19",
      img: "/images/momense.png",
      // img: img19,
    },
  ];
  return (
    <>
      <div className="mx-5">
        <p className="fw-bold">
          PARTENAIRES DE LA FONDATION PARIS SAINT-GERMAIN
        </p>
        <div className="row">
          {imgsData.map((val, index) => (
            <div key={index} className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src={val.img} width={120} alt="" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Partenaires;
