import React from "react";
import Navbar from "../../../components/Navbar";
// import img1 from "../../../images/jobs-philiosophie-2324.jpg";
const PhilosophieDetail = () => {
  return (
    <>
      <Navbar />
      <div className=" position-relative">
        <img
          src="/images/jobs-philiosophie-2324.jpg"
          // src={img1}
          alt=""
          className="object-fit-cover w-100"
          style={{ height: "25rem" }}
        />
        <h2 className="position-absolute fw-bold start-50 bottom-50 text-light">
          Notre Philosophie
        </h2>
      </div>
      <div className="col-md-7 mx-auto py-5">
        <h3 className="pb-3">
          Le Paris Saint-Germain est convaincu que l’ensemble de ses talents
          contribuent aux succès du Club.
        </h3>
        <p className="fs-17">
          Le Paris Saint-Germain a été créé en 1970. Depuis le rachat du Club
          par Qatar Sports Investments (QSI) en 2011, il s’est transformé pour
          devenir l’un des meilleurs clubs de football et l’une des principales
          marques de sport au monde.
        </p>
        <p className="fs-17">
          Le succès et la renommée du Club de la capitale se sont construits sur
          le terrain, mais également dans ses coulisses, avec le travail de plus
          de 700 collaborateurs qui œuvrent quotidiennement au rayonnement du
          Club.
        </p>
        <p className="fs-17">
          Billetterie et hospitalités, sponsoring, médias, services corporate,
          médical, sportif… Chaque collaborateur a l’opportunité de s’épanouir
          au sein du Club, dans son domaine de compétences.
        </p>
        <p className="fs-17">
          Nous offrons à nos collaborateurs la possibilité de vivre des moments
          uniques au sein de notre grande famille tout en portant nos valeurs
          d’ambition, d’excellence, de solidarité et de partage.
        </p>
        <p className="fs-17">
          Le Paris Saint-Germain tient à développer l’inclusion de chacun dans
          le monde professionnel. Fiers des engagements portés par l’ensemble de
          ses collaborateurs, le Club s'inscrit dans une politique de diversité
          et d’insertion, notamment en faveur des personnes en situation de
          handicap.
        </p>
      </div>
    </>
  );
};

export default PhilosophieDetail;
