import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
// import img from "../../images/_am14048_nqkljqht.jpg";
import { MdCreditCard } from "react-icons/md";
import photosData from "./PhotosMedia";
import { Link } from "react-router-dom";

const Photos = () => {
  const [activeFilter, setActiveFilter] = useState("TOUS LES DIAPORAMAS");
  const [filteredCards, setFilteredCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 10;

  const handleFilterClick = (filter) => {
    setActiveFilter(filter === activeFilter ? "" : filter);
  };

  useEffect(() => {
    setActiveFilter("TOUS LES DIAPORAMAS");
    setFilteredCards(photosData);
  }, []);

  useEffect(() => {
    const updatedCards =
      activeFilter === "TOUS LES DIAPORAMAS"
        ? photosData
        : photosData.filter((card) => card.category === activeFilter);
    setFilteredCards(updatedCards);
    setCurrentPage(1); // Reset to first page when filter changes
  }, [activeFilter]);

  // Logic to calculate the current cards to display based on pagination
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredCards.slice(indexOfFirstCard, indexOfLastCard);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Navbar />
      <div className="mx-5">
        <div className="py-5">
          <h1 className="fw-bold">Photos</h1>
          <p>A LA UNE</p>

          <div className="row align-items-center cursor-pointer">
            <div className="col-md-6">
              <div class="image-container">
                <img
                  src="/images/_am14048_nqkljqht.jpg"
                  // src={img}
                  alt=""
                  style={{ height: "30rem" }}
                  class="zoom-img"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex gap-2 pb-3">
                <span className="text-blue fw-bold">ÉQUIPE PREMIÈRE</span>
                <span className="text-secondary fw-bold">|</span>
                <span className="text-secondary fw-bold">29 mars 2024</span>
              </div>
              <h1 className="fw-bold">
                Les photos de l'entraînement de vendredi
              </h1>
            </div>
          </div>
        </div>

        <div className="d-flex gap-5 pt-5 pb-4">
          <a
            href="##"
            className={
              activeFilter === "TOUS LES DIAPORAMAS"
                ? "text-blue fw-500"
                : "fw-500"
            }
            onClick={() => handleFilterClick("TOUS LES DIAPORAMAS")}
          >
            TOUS LES DIAPORAMAS
          </a>
          <a
            href="##"
            className={
              activeFilter === "EQUIPE PREMIÈRE" ? "text-blue fw-500" : "fw-500"
            }
            onClick={() => handleFilterClick("EQUIPE PREMIÈRE")}
          >
            EQUIPE PREMIÈRE
          </a>
          <a
            href="##"
            className={
              activeFilter === "FOOTBALL FEMININ"
                ? "text-blue fw-500"
                : "fw-500"
            }
            onClick={() => handleFilterClick("FOOTBALL FEMININ")}
          >
            FOOTBALL FEMININ
          </a>
          <a
            href="##"
            className={
              activeFilter === "HANDBALL" ? "text-blue fw-500" : "fw-500"
            }
            onClick={() => handleFilterClick("HANDBALL")}
          >
            HANDBALL
          </a>
          <a
            href="##"
            className={activeFilter === "CLUB" ? "text-blue fw-500" : "fw-500"}
            onClick={() => handleFilterClick("CLUB")}
          >
            CLUB
          </a>
        </div>
        <div className="row">
          {currentCards.map((card, index) => (
            <div key={index} className="col-md-3 py-3">
              <Link to={`/photoDetail/${card.id}`}>
                <a href="##">
                  <div style={{ position: "relative" }}>
                    <div className="image-container">
                      <img src={card.img} alt="" className="zoom-img" />
                    </div>
                    <MdCreditCard
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "10px",
                      }}
                      className="text-light fs-5"
                    />
                  </div>{" "}
                  <div className="p-3 card-body">
                    <div className="d-flex gap-2">
                      <span className="text-blue fw-bold">{card.category}</span>
                      <span className="text-secondary fw-bold">|</span>
                      <span className="text-secondary fw-bold">
                        {card.date}
                      </span>
                    </div>
                    <h5 className="fw-bold mt-2">{card.title}</h5>
                  </div>
                </a>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center pb-5">
          <li className={`page-item ${currentPage === 1 && "disabled"}`}>
            <a
              className="page-link"
              href="##"
              onClick={() => paginate(currentPage - 1)}
            >
              Previous
            </a>
          </li>
          {[
            ...Array(Math.ceil(filteredCards.length / cardsPerPage)).keys(),
          ].map((number) => (
            <li
              key={number}
              className={`page-item ${currentPage === number + 1 && "active"}`}
            >
              <a
                className="page-link"
                href="##"
                onClick={() => paginate(number + 1)}
              >
                {number + 1}
              </a>
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === Math.ceil(filteredCards.length / cardsPerPage) &&
              "disabled"
            }`}
          >
            <a
              className="page-link"
              href="##"
              onClick={() => paginate(currentPage + 1)}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Photos;
