// import React from "react";
// import Navbar from "../components/Navbar";

// const Login = () => {
//   return (
//     <>
//       <Navbar />
//       <div>

//       </div>
//     </>
//   );
// };

// export default Login;
import React from "react";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";

const Signup = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <>
      <Navbar />
      <div className="py-5">
        <div className="container_form container">
          <form onSubmit={handleSubmit}>
            <div className="title">Signup</div>
            <p className="text-center">
              Already have an account?{" "}
              <Link to={"/login"} className="text-blue fw-bold ">
                Login
              </Link>
            </p>
            <div className="input-box underline">
              <input type="text" placeholder="First Name" required />
              <div className="underline"></div>
            </div>
            <div className="input-box underline">
              <input type="text" placeholder="Last Name" required />
              <div className="underline"></div>
            </div>
            <div className="input-box underline">
              <input type="email" placeholder="Enter Your Email" required />
              <div className="underline"></div>
            </div>
            <div className="input-box">
              <input type="date" placeholder="Date of Birth" required />
              <div className="underline"></div>
            </div>
            <div className="input-box">
              <input
                type="password"
                placeholder="Enter Your Password"
                required
              />
              <div className="underline"></div>
            </div>
            <div className="input-box">
              <input
                type="password"
                placeholder="Enter Your Confirm Password"
                required
              />
              <div className="underline"></div>
            </div>
            <div class="form-check my-4">
              <input
                class="form-check-input rounded-0 shadow-none"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label fs-17 ms-2" for="flexCheckDefault">
                I’d like to receive news and promotional information from
                Chelsea FC and its{" "}
                <span className="text-blue fw-bold">
                  {" "}
                  official sponsors and partners
                </span>{" "}
                via email
              </label>
            </div>
            <div class="form-check mb-3">
              <input
                class="form-check-input rounded-0 shadow-none"
                type="checkbox"
                value=""
                id="flexCheckDefault2"
              />
              <label
                class="form-check-label fs-17 ms-2"
                for="flexCheckDefault2"
              >
                I confirm I am over the age of 13 and I agree to the{" "}
                <span className="text-blue fw-bold"> Terms & Conditions</span>{" "}
                and
                <span className="text-blue fw-bold"> Privacy Policy*</span>
              </label>
            </div>
            <div className="input-box button">
              <input type="submit" value="Continue" />
            </div>
          </form>

          {/* <div className="option">or Connect With Social Media</div> */}
          <div className="row align-items-center justify-content-center mx-1">
            <div
              className="col-md-5"
              style={{ height: "2px", backgroundColor: "#ccc" }}
            ></div>
            <div className="col-md-2 d-flex justify-content-center">or</div>
            <div
              className="col-md-5"
              style={{ height: "2px", backgroundColor: "#ccc" }}
            ></div>
          </div>
          <div className="twitter">
            <a href="#">
              <FcGoogle className=" fs-3" />
              Sign in With Google
            </a>
          </div>
          <div className="facebook text-light">
            <a href="#">
              <FaFacebook className="fs-4" />
              Sign in With Facebook
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
