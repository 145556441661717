import React from "react";
import Navbar from "../../../components/Navbar";
// import img1 from "../../../images/jobs-philiosophie-2324.jpg";
// import img2 from "../../../images/jobs-coeur.jpg";
// import img3 from "../../../images/jobs-etudiants.jpg";
// import img4 from "../../../images/jobs-famille.jpg";
// import img5 from "../../../images/jobs-rejoindre-22-tb.jpg";
import { Link } from "react-router-dom";

const Carrières = () => {
  return (
    <>
      <Navbar />
      <div
        className="bannerCarrieres"
        style={{
          backgroundImage:
            'linear-gradient(rgba(20, 5, 133, 0.3), rgba(20, 5, 133, 0.3)), url("images/jobs-carrieres-cover.jpg")',
        }}
      >
        <h1 className="fw-bold text-light">Emplois & Carrières</h1>
      </div>
      <div className="mx-auto col-md-7">
        <h3 className="py-5">
          Sur le terrain comme en coulisses, plus de 700 talents font la
          renommée de notre Club. Découvrez le quotidien de nos collaborateurs
          et nos offres à pourvoir pour rejoindre notre famille.
        </h3>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 py-3">
            <div className=" position-relative">
              <Link to={"/philosophieDetail"}>
                <div className="image-container">
                  <img
                    src="/images/jobs-philiosophie-2324.jpg"
                    // src={img1}
                    alt=""
                    className="zoom-img w-100"
                    style={{ height: "20rem" }}
                  />
                </div>
                <h4 className="position-absolute start-0 bottom-0 text-light ps-3">
                  Notre Philosophie
                </h4>
              </Link>
            </div>
          </div>
          <div className="col-md-6 py-3">
            <div className=" position-relative">
              <Link to={"/coeurDetail"}>
                <div className="image-container">
                  <img
                    src="/images/jobs-coeur.jpg"
                    // src={img2}
                    alt=""
                    className="zoom-img w-100"
                    style={{ height: "20rem" }}
                  />
                </div>
                <h4 className="position-absolute start-0 bottom-0 text-light ps-3">
                  Au coeur du club
                </h4>
              </Link>
            </div>
          </div>
          <div className="col-md-6 py-3">
            <div className=" position-relative">
              <Link to={"/etudiantsDetail"}>
                <div className="image-container">
                  <img
                    src="/images/jobs-etudiants.jpg"
                    // src={img3}
                    alt=""
                    className="zoom-img w-100"
                    style={{ height: "20rem" }}
                  />
                </div>
                <h4 className="position-absolute start-0 bottom-0 text-light ps-3">
                  Etudiants
                </h4>
              </Link>
            </div>
          </div>
          <div className="col-md-6 py-3">
            <div className=" position-relative">
              <Link to={"/notreFamille"}>
                <div className="image-container">
                  <img
                    src="/images/jobs-famille.jpg"
                    // src={img4}
                    alt=""
                    className="zoom-img w-100"
                    style={{ height: "20rem" }}
                  />
                </div>
                <h4 className="position-absolute start-0 bottom-0 text-light ps-3">
                  Notre famille
                </h4>
              </Link>
            </div>
          </div>
          <div className="col-md-6 py-3">
            <div className=" position-relative">
              <Link to={"/nousRejoindre"}>
                <div className="image-container">
                  <img
                    src="/images/jobs-rejoindre-22-tb.jpg"
                    // src={img5}
                    alt=""
                    className="zoom-img w-100"
                    style={{ height: "20rem" }}
                  />
                </div>
                <h4 className="position-absolute start-0 bottom-0 text-light ps-3">
                  Nous Rejoindre
                </h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Carrières;
