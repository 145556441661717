import React from "react";
import Navbar from "../../components/Navbar";
// import cup1 from "../../images/foot-ligue-1.png";
// import cup2 from "../../images/foot-french-cup.png";
// import cup3 from "../../images/foot-league-cup.png";
// import cup4 from "../../images/foot-champions-trophy.png";
// import cup5 from "../../images/foot-cup-winners-cup.png";
// import winner1 from "../../images/honours-ligue.jpg";
// import winner2 from "../../images/honours-french-cup-2021.jpg";
// import winner3 from "../../images/honours-cup-winners-cup.jpg";

const Palmares = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row justify-content-center gap-4 py-5">
          <div className="col-md-2">
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <div className="d-flex align-items-center">
                <img
                  src="/images/foot-ligue-1.png"
                  // src={cup1}
                  width={70}
                  className="object-fit-cover"
                  alt=""
                />
                <h1 className="text-blue fw-bold mb-0 fs-big">11</h1>
              </div>
              <p className="text-secondary fw-bold">Ligue 1</p>
            </div>
          </div>
          <div className="col-md-2">
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <div className="d-flex align-items-center">
                <img
                  src="/images/foot-french-cup.png"
                  // src={cup2}
                  width={70}
                  className="object-fit-cover"
                  alt=""
                />
                <h1 className="text-blue fw-bold mb-0 fs-big">14</h1>
              </div>
              <p className="text-secondary fw-bold">Coupe de France</p>
            </div>
          </div>
          <div className="col-md-2">
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <div className="d-flex align-items-center">
                <img
                  src="/images/foot-league-cup.png"
                  // src={cup3}
                  width={70}
                  className="object-fit-cover"
                  alt=""
                />
                <h1 className="text-blue fw-bold mb-0 fs-big">9</h1>
              </div>
              <p className="text-secondary fw-bold">Coupe de la Ligue</p>
            </div>
          </div>
          <div className="col-md-2">
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <div className="d-flex align-items-center">
                <img
                  src="/images/foot-champions-trophy.png"
                  // src={cup4}
                  width={70}
                  className="object-fit-cover"
                  alt=""
                />
                <h1 className="text-blue fw-bold mb-0 fs-big">12</h1>
              </div>
              <p className="text-secondary fw-bold">Trophée des Champions</p>
            </div>
          </div>
          <div className="col-md-2">
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <div className="d-flex align-items-center">
                <img
                  src="/images/foot-cup-winners-cup.png"
                  // src={cup5}
                  width={70}
                  className="object-fit-cover"
                  alt=""
                />
                <h1 className="text-blue fw-bold mb-0 fs-big">1</h1>
              </div>
              <p className="text-secondary fw-bold">
                Coupe d'Europe des Vainqueurs de Coupe
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-5">
        <div className="py-4">
          <h4 className="fw-bold">Ligue 1</h4>
          <div className="row">
            <div className="col-md-6">
              <img
                src="/images/honours-ligue.jpg"
                // src={winner1}
                className="w-100 h-100 object-fit-cover"
                alt=""
              />
            </div>
            <div className="col-md-6">
              {/* ----- */}
              <div className="container h-100">
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <td>2023</td>
                      <td className="d-flex align-items-center gap-1">
                        <div
                          className="bg-success rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>27</span>
                        <div
                          className="bg-danger rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>7</span>
                        <div
                          className="bg-secondary rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>4</span>
                      </td>
                      <td>85 </td>
                    </tr>
                    <tr>
                      <td>2022</td>
                      <td className="d-flex align-items-center gap-1">
                        <div
                          className="bg-success rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>26</span>
                        <div
                          className="bg-danger rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>4</span>
                        <div
                          className="bg-secondary rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>8</span>
                      </td>
                      <td>86 </td>
                    </tr>
                    <tr>
                      <td>2020</td>
                      <td className="d-flex align-items-center gap-1">
                        <div
                          className="bg-success rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>22</span>
                        <div
                          className="bg-danger rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>3</span>
                        <div
                          className="bg-secondary rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>2</span>
                      </td>
                      <td> 68</td>
                    </tr>
                    <tr>
                      <td>2019</td>
                      <td className="d-flex align-items-center gap-1">
                        <div
                          className="bg-success rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>29</span>
                        <div
                          className="bg-danger rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>5</span>
                        <div
                          className="bg-secondary rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>4</span>
                      </td>
                      <td> 91</td>
                    </tr>
                    <tr>
                      <td>2018</td>
                      <td className="d-flex align-items-center gap-1">
                        <div
                          className="bg-success rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>29</span>
                        <div
                          className="bg-danger rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>3</span>
                        <div
                          className="bg-secondary rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>6</span>
                      </td>
                      <td> 93</td>
                    </tr>
                    <tr>
                      <td>2016</td>
                      <td className="d-flex align-items-center gap-1">
                        <div
                          className="bg-success rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>30</span>
                        <div
                          className="bg-danger rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>2</span>
                        <div
                          className="bg-secondary rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>6</span>
                      </td>
                      <td>96 </td>
                    </tr>
                    <tr>
                      <td>2015</td>
                      <td className="d-flex align-items-center gap-1">
                        <div
                          className="bg-success rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>24</span>
                        <div
                          className="bg-danger rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>3</span>
                        <div
                          className="bg-secondary rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>11</span>
                      </td>
                      <td>83 </td>
                    </tr>
                    <tr>
                      <td>2014</td>
                      <td className="d-flex align-items-center gap-1">
                        <div
                          className="bg-success rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>27</span>
                        <div
                          className="bg-danger rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>3</span>
                        <div
                          className="bg-secondary rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>8</span>
                      </td>
                      <td> 89</td>
                    </tr>
                    <tr>
                      <td>2013</td>
                      <td className="d-flex align-items-center gap-1">
                        <div
                          className="bg-success rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>25</span>
                        <div
                          className="bg-danger rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>5</span>
                        <div
                          className="bg-secondary rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>8</span>
                      </td>
                      <td> 83</td>
                    </tr>
                    <tr>
                      <td>1994</td>
                      <td className="d-flex align-items-center gap-1">
                        <div
                          className="bg-success rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>24</span>
                        <div
                          className="bg-danger rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>3</span>
                        <div
                          className="bg-secondary rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>11</span>
                      </td>
                      <td>59 </td>
                    </tr>
                    <tr>
                      <td>1986</td>
                      <td className="d-flex align-items-center gap-1">
                        <div
                          className="bg-success rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>23</span>
                        <div
                          className="bg-danger rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>5</span>
                        <div
                          className="bg-secondary rounded-pill"
                          style={{ height: "6px", width: "6px" }}
                        ></div>
                        <span>10</span>
                      </td>
                      <td>56 </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4">
          <h4 className="fw-bold">Coupe de France</h4>
          <div className="row">
            <div className="col-md-6 ">
              <img
                src="/images/honours-french-cup-2021.jpg"
                // src={winner2}
                className="w-100 h-100 object-fit-cover"
                alt=""
              />
            </div>
            <div className="col-md-6">
              {/* ----- */}
              <div className="container h-100">
                <table class="table table-striped ">
                  <tbody>
                    <tr>
                      <td>2021</td>
                      <td>Monaco</td>
                      <td> 2-0</td>
                    </tr>
                    <tr>
                      <td>2020</td>
                      <td>Saint-Etienne</td>
                      <td> 1-0</td>
                    </tr>
                    <tr>
                      <td>2018</td>
                      <td>Les Herbiers</td>
                      <td> 2-0</td>
                    </tr>
                    <tr>
                      <td>2017</td>
                      <td>Angers</td>
                      <td> 1-0</td>
                    </tr>
                    <tr>
                      <td>2016</td>
                      <td>Marseille</td>
                      <td> 4-2</td>
                    </tr>
                    <tr>
                      <td>2015</td>
                      <td>Auxerre</td>
                      <td>1-0 </td>
                    </tr>
                    <tr>
                      <td>2010</td>
                      <td>Monaco</td>
                      <td> 1-0 AP</td>
                    </tr>
                    <tr>
                      <td>2006</td>
                      <td>Marseille</td>
                      <td>2-1 </td>
                    </tr>
                    <tr>
                      <td>2004</td>
                      <td>Châteauroux</td>
                      <td> 1-0</td>
                    </tr>
                    <tr>
                      <td>1998</td>
                      <td>Lens</td>
                      <td>2-1 </td>
                    </tr>
                    <tr>
                      <td>1995</td>
                      <td>Strasbourg</td>
                      <td>1-0 </td>
                    </tr>
                    <tr>
                      <td>1993</td>
                      <td>Nantes</td>
                      <td> 3-0</td>
                    </tr>
                    <tr>
                      <td>1983</td>
                      <td>Nantes</td>
                      <td>3-2 </td>
                    </tr>
                    <tr>
                      <td>1982</td>
                      <td> Saint-Etienne</td>
                      <td>2-2 AP (6-5 TAB) </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4">
          <h4 className="fw-bold">Coupe d'Europe des Vainqueurs de Coupe</h4>
          <div className="row">
            <div className="col-md-6 ">
              <img
                src="/images/honours-cup-winners-cup.jpg"
                // src={winner3}
                className="w-100 h-100 object-fit-cover"
                alt=""
              />
            </div>
            <div className="col-md-6">
              <div className="container">
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <td>1996</td>
                      <td>Rapid Vienne</td>
                      <td> 1-0</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Palmares;
