import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { MdCreditCard } from "react-icons/md";
import cardsData from "./ArticleData";
import PhotosMedia from "../medias/PhotosMedia";
import videoData from "../medias/VideoData";
import Footer from "../../components/Footer";
// import img from "../../images/banner.webp";
import { Link } from "react-router-dom";
import { FaPlay } from "react-icons/fa";

const Actualite = () => {
  const [activeFilter, setActiveFilter] = useState("TOUS LES DIAPORAMAS");
  const handleFilterClick = (filter) => {
    setActiveFilter(filter === activeFilter ? "" : filter);
  };

  const filteredCards =
    activeFilter === "TOUS LES DIAPORAMAS"
      ? cardsData
      : cardsData.filter((card) => card.type === activeFilter);

  useEffect(() => {
    setActiveFilter("TOUS LES DIAPORAMAS");
  }, []);

  return (
    <>
      <Navbar />
      <div>
        <img
          src="/images/banner.png"
          // src={img}
          alt="banner"
          className="w-100 object-fit-cover"
          height={500}
        />
        <div className="mx-4 py-4">
          <div className="d-flex gap-5 py-5">
            <a
              href="##"
              className={
                activeFilter === "TOUS LES DIAPORAMAS"
                  ? "text-blue fw-bold"
                  : "fw-bold"
              }
              onClick={() => handleFilterClick("TOUS LES DIAPORAMAS")}
            >
              TOUS LES DIAPORAMAS
            </a>
            <a
              href="##"
              className={
                activeFilter === "ARTICLES" ? "text-blue fw-bold" : "fw-bold"
              }
              onClick={() => handleFilterClick("ARTICLES")}
            >
              ARTICLES
            </a>
            <a
              href="##"
              className={
                activeFilter === "VIDÉOS" ? "text-blue fw-bold" : "fw-bold"
              }
              onClick={() => handleFilterClick("VIDÉOS")}
            >
              VIDÉOS
            </a>
            <a
              href="##"
              className={
                activeFilter === "DIAPORAMAS" ? "text-blue fw-bold" : "fw-bold"
              }
              onClick={() => handleFilterClick("DIAPORAMAS")}
            >
              DIAPORAMAS
            </a>
          </div>
          <div className="row">
            {filteredCards.map((card, index) => (
              <div key={index} className="col-md-3 py-3">
                <Link to={`/articleDetail/${card.id}`}>
                  <div style={{ position: "relative" }}>
                    <div className="image-container">
                      <img src={card.img} alt="" className="zoom-img" />
                    </div>
                    <MdCreditCard
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "10px",
                      }}
                      className="text-light fs-5"
                    />
                  </div>{" "}
                  <div className="p-3 card-body">
                    <div className="d-flex gap-2">
                      <span className="text-blue fw-bold">{card.category}</span>
                      <span className="text-secondary fw-bold">|</span>
                      <span className="text-secondary fw-bold">
                        {card.date}
                      </span>
                    </div>
                    <h5 className="fw-bold mt-2">{card.title}</h5>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="row">
            {PhotosMedia.map((card, index) => (
              <div key={index} className="col-md-3 py-3">
                <Link to={`/photoDetail/${card.id}`}>
                  <a href="##">
                    <div style={{ position: "relative" }}>
                      <div className="image-container">
                        <img src={card.img} alt="" className="zoom-img" />
                      </div>
                      <MdCreditCard
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "10px",
                        }}
                        className="text-light fs-5"
                      />
                    </div>{" "}
                    <div className="p-3 card-body">
                      <div className="d-flex gap-2">
                        <span className="text-blue fw-bold">
                          {card.category}
                        </span>
                        <span className="text-secondary fw-bold">|</span>
                        <span className="text-secondary fw-bold">
                          {card.date}
                        </span>
                      </div>
                      <h5 className="fw-bold mt-2">{card.title}</h5>
                    </div>
                  </a>
                </Link>
              </div>
            ))}
          </div>
          <div className="row">
            {videoData.map((val, index) => (
              <div className="col-md-3 mb-3" key={index}>
                <Link to={`/videoDetail/${val.id}`}>
                  <div className="shadow h-100">
                    <div style={{ position: "relative" }}>
                      <div className="image-container">
                        <video className="w-100 zoom-img" poster={val.img}>
                          <source src={val.video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <div
                          style={{
                            position: "absolute",
                            left: "5px",
                            bottom: "12px",
                          }}
                          className="bg-blue py-2 px-3"
                        >
                          <FaPlay className="text-light" />
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="d-flex gap-2">
                        <p className="fw-bold mb-0">{val.category}</p>
                        <p className="mb-0">|</p>
                        <p className="mb-0">{val.date}</p>
                      </div>
                      <p className="fs-17 fw-bold">{val.title}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="col-md-2 mx-auto">
            <button className="btn bg-blue text-light rounded-0 w-100 py-2">
              VOIR PLUS
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Actualite;
