import React from "react";
// import banner from "../../images/new-banner-p-s-g-2100x1000.jpg";
import Navbar from "../../components/Navbar";

const MaillotsPortés = () => {
  return (
    <>
      <Navbar />
      <img src="/images/new-banner-p-s-g-2100x1000.jpg" className="w-100" alt="" />
    </>
  );
};

export default MaillotsPortés;
