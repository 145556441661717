import React from "react";
// import img2 from "../../../images/jobs-coeur.jpg";

const CoeurDetail = () => {
  return (
    <>
      <div className=" position-relative">
        <img
          src="/images/jobs-coeur.jpg"
          // src={img2}
          alt=""
          className="object-fit-cover w-100"
          style={{ height: "25rem" }}
        />
        <h2 className="position-absolute fw-bold start-50 bottom-50 text-light">
          Au coeur du club
        </h2>
      </div>
      <div className="col-md-7 mx-auto py-5">
        <h3 className="text-center">
          A la découverte de ceux qui font le Paris Saint-Germain.
        </h3>

        <div className="row pt-5">
          <div className="col-md-4 text-center">
            <h1 className="text-blue fw-bold">34</h1>
            <h4 className="fw-bold">ANS</h4>
            <p className="fs-17">de moyenne d'âge</p>
          </div>
          <div className="col-md-4 text-center">
            <h1 className="text-blue fw-bold">700</h1>
            <h4 className="fw-bold">TALENTS</h4>
            <p className="fs-17">au sein du club</p>
          </div>
          <div className="col-md-4 text-center">
            <h1 className="text-blue fw-bold">62%</h1>
            <h4 className="fw-bold">DE CDI</h4>
            <p className="fs-17">parmi les salariés</p>
          </div>
        </div>
        <p className="fs-17">
          Le Paris Saint-Germain met à l'honneur ses collaborateurs au travers
          d'une série de portraits d'employés, intitulée « Côté Club ». Ces
          vidéos sont publiées sur ses plateformes digitales et les réseaux
          sociaux pour faire découvrir à tous, les visages des femmes et hommes
          qui œuvrent en coulisses pour le Club. Chacun y raconte son parcours,
          son histoire avec le Paris Saint-Germain.
        </p>

        <video className="w-100" controls autoplay loop muted>
          <source src="/images/video-banner.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="w-100" controls autoplay loop muted>
          <source src="/images/video-banner.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="w-100" controls autoplay loop muted>
          <source src="/images/video-banner.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
};

export default CoeurDetail;
