import React from "react";
// import img1 from "../../../images/nike.webp";
// import img2 from "../../../images/inetum.png";
// import img3 from "../../../images/qatar-airways.png";
// import img4 from "../../../images/bein.svg";
// import img5 from "../../../images/reuter.png";
// import img6 from "../../../images/immerialys.png";
// import img7 from "../../../images/qnb.svg";
// import img8 from "../../../images/ooredoo.svg";
// import img9 from "../../../images/mcdonalds.png";
// import img10 from "../../../images/daniel-hechter.png";
// import img11 from "../../../images/select.png";
// import img12 from "../../../images/gerflor.png";
// import img13 from "../../../images/winia.png";
// import img14 from "../../../images/veolia.png";
// import img15 from "../../../images/novatlas-3.png";
// import img16 from "../../../images/zumub2324.png";
// import img17 from "../../../images/delonghi2.png";

const Handball = () => {
  return (
    <>
      <div className="container">
        <div>
          <p className="fs-17 fw-bold mt-5">PARTENAIRES PRINCIPAUX</p>
          <div className="row">
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/nike.png" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/qatar-airways.png" width={120} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div>
          <p className="fs-17 fw-bold mt-5">PARTENAIRES OFFICIELS</p>
          <div className="row">
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/qatar-airways.png" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/bein.svg" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/reuter.png" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/immerialys.png" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/qnb.svg" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/ooredoo.svg" width={120} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div>
          <p className="fs-17 fw-bold mt-5">FOURNISSEURS OFFICIELS</p>
          <div className="row">
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/mcdonalds.png" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/daniel-hechter.png" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/select.png" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/gerflor.png" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/winia.png" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/veolia.png" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/novatlas-3.png" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/zumub2324.png" width={120} alt="" />
              </a>
            </div>
            <div className="col-md-3 py-2">
              <a
                href="##"
                className="bg-light d-flex justify-content-center align-items-center"
                style={{ height: "13rem" }}
              >
                <img src="/images/delonghi2.png" width={120} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Handball;
