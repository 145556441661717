import React from "react";
// import img1 from "../../images/bloc-1.jpg";
// import img2 from "../../images/bloc-2.jpg";

const Solidaire = () => {
  const ofkData = [
    {
      id: "1",
      img: "/images/bloc-1.jpg",
      // img: img1,
      title: "Un engagement qui a du sens      ",
      para: " Depuis leur création, notre Fondation et notre Fonds de dotation ont pour vocation d’aider les enfants défavorisés ou malades, les jeunes et les communautés en difficulté. Découvrez leurs actions en téléchargeant la brochure de présentation 2022/23.",
      button: "Télécharger",
    },
    {
      id: "2",
      img: "/images/bloc-2.jpg",
      // img: img2,
      title: "Soutenir le fonds de dotation      ",
      para: " Un engagement qui a du sens pour vous ou votre entreprise. Notre souhait est de proposer aux particuliers et aux entreprises des programmes dans lesquels ils croient et retrouvent leurs valeurs, leur ambition, leurs préoccupations.Découvrez les actions à soutenir et les différents types de mécénat en téléchargeant les fiches projets.",
      button: "Télécharger",
    },
  ];
  return (
    <>
      <div className="col-md-8 mx-auto py-5 px-4 shadow-bottom">
        <h3 className="text-center py-4">
          Le Fonds de dotation Paris Saint-Germain fait appel à la générosité du
          public pour son projet d'aide alimentaire #CantineSolidaire
        </h3>
        <h4 className="fw-bold text-blue">NOUS SOUTENIR, POURQUOI?</h4>
        <p className="fs-17">
          En France, 36% des étudiants sautent régulièrement un repas, faut de
          moyens financiers. 91,7% d'entres eux vivent avec moins de 1000 euros
          par mois. Le programme Cantine Solidaire s’appuie sur une
          collaboration solide avec les associations Studhelp et Cop1, et
          cherche à venir en aide aux étudiants les plus en difficulté.
        </p>
        <p className="fs-17">
          Le Fonds de dotation a décidé de mobiliser ses partenaires et les
          supporter du Club, et d'appeler aux dons pour financer les repas de
          ces jeunes, en créditant des titres-restaurants qui leur seront
          distribués.
        </p>
        <p className="fs-17">
          Cette initiative témoigne de la volonté indéfectible du Fonds de
          dotation Paris Saint-Germain de répondre aux besoins essentiels des
          étudiants qui traversent des difficultés, afin de leur assurer un
          quotidien plus serein.
        </p>
        <p className="fs-17">
          En complément, 3 événements “Cantine Solidaire” par saison sont
          organisés au Parc des Princes, afin de proposer un repas chaud et des
          animations sportives à 1 000 étudiants en situation de précarité.
        </p>
        <p className="fs-17">
          Chaque don compte et permettra d'augmenter le nombre d'étudiants aidés
          ! Un grand merci pour votre générosité.
        </p>
        <h4 className="fw-bold text-blue">
          DESCRIPTION DES POSTES DE DÉPENSES
        </h4>
        <p className="fs-17">
          10€ = 1 titre-restaurant crédité = 1 repas offert à un étudiant
        </p>
        <h4 className="fw-bold text-blue">
          BÉNÉFICIAIRES FINAUX DE LA COLLECTE
        </h4>
        <p className="fs-17">
          Les fonds levés seront crédités sur des titres-restaurants, distribués
          aux étudiants identifiés par nos 2 associations étudiantes partenaires
          :
        </p>
        <ul>
          <li>Studhelp</li>
          <li>Cop1</li>
        </ul>
        <h4 className="fw-bold text-blue">PORTEURS DU PROJET</h4>
        <p className="fs-17">
          Mis en place en 2013, le Fonds de dotation du Paris Saint-Germain
          permet de donner une impulsion à ses programmes en collectant des dons
          de mécénat. Les fonds collectés sont entièrement dédiés à la mise en
          oeuvre des actions en faveur des jeunes et des enfants. C’est grâce à
          ces soutiens que le Fonds de dotation peut augmenter le nombre de
          programmes menés et de jeunes qui en bénéficient.
        </p>
        <div className="px-5 py-5">
          <h1 className="display-4 fw-bold text-blue">Toutes nos excuses !</h1>
          <p className="lead">Cet accès n'est pas autorisé</p>
          <p className="fs-17">Code d'erreur : 403</p>
          <p className="fs-17">
            Désolé, la page à laquelle vous voulez accéder n'est pas accessible
            et demande une autorisation spécifique.
          </p>
          <button className="btn bg-blue text-light py-2 px-3 rounded-0">
            Retour à l'accueil
          </button>
        </div>
      </div>
      <div className="py-5">
        <div className="container bg-white py-5">
          {ofkData.map((val, index) => (
            <div
              key={index}
              className={`row align-items-center ${
                index % 2 !== 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="col-md-6 px-5 py-5">
                <h1 className="text-blue fw-bold dispaly-4">{val.title}</h1>
                <p className="fs-17">{val.para}</p>
                <button className="btn bg-blue text-white rounded-0 py-2 px-3">
                  {val.button}
                </button>
              </div>
              <div className="col-md-6 py-5">
                <img src={val.img} className="w-100" alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Solidaire;
