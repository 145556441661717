import React from "react";
import Navbar from "../../components/Navbar";

// import img1 from "../../images/les-entrainements.jpg";
// import img2 from "../../images/la-coupe.jpg";

const ClubTour = () => {
  const Data = [
    {
      id: "1",
      img: "/images/les-entrainements.jpg",
      // img: img1,
      title: "Les Entraînements du Club Tour",
      para: " Animés par l’expertise d’une équipe de 6 encadrants, les Entraînements du Club Tour permettent à vos jeunes licencié(e)s U11 et U13 de s’entrainer, le temps d’un après-midi, comme les joueuses et joueurs professionnels du Paris Saint-Germain. Au programme de cet entraînement 100% Rouge & Bleu :   <br> 5 exercices footballistiques techniques      <br> Des rencontres d’exception avec les équipes du Paris Saint-Germain      <br>  Un atelier photomaton innovant      <br>  Des goodies et souvenirs à vie      ",
      button: "Informations & Inscriptions",
    },
    {
      id: "2",
      img: "/images/la-coupe.jpg",
      // img: img2,
      title: "La Coupe du Club Tour",
      para: " Faites vivre à vos licencié(e)s une expérience Rouge & Bleu unique. Après s’être déplacé sur les terrains des clubs de la région d’Île-de-France, le Paris Saint-Germain accueillera le dimanche 21 avril 2024 la Coupe du Club Tour au Campus de Poissy.  <br>  24 clubs seront sélectionnés pour participer à ce tournoi avec leurs équipes U11 (féminines & masculines).",
      button: "Règlement & Inscriptions",
    },
  ];
  return (
    <>
      <Navbar />
      <div
        className="bannerClub"
        style={{
          backgroundImage: 'url("/images/header-clubtour-24.jpg")',
        }}
      ></div>
      <div className="col-md-7 mx-auto py-5">
        <h3>
          Depuis 4 ans, le Paris Saint-Germain va à la rencontre des clubs de
          football amateurs d’Île-de-France pour les remercier du travail
          effectué qui fait aujourd’hui de cette région un vivier de talents
          exceptionnel.
        </h3>
        <p className="py-4 mb-0">
          Club de la nouvelle génération, le Paris Saint-Germain souhaite
          inspirer les jeunes générations au travers de programmes fondés sur
          plusieurs valeurs comme l’excellence, l’innovation et le développement
          des potentiels.
        </p>
        <p className="py-4 mb-0">
          Le Club Tour s’inscrit dans cette perspective en allant au cœur du
          football francilien à la rencontre des jeunes licencié(e) de clubs
          pour leur offrir une expérience 100% Paris Saint-Germain inspirante,
          innovante et impactante.
        </p>
        <p className="py-4 mb-0">
          Cette année le Club Tour se décompose en deux temps forts :
        </p>
        <ol>
          <li>Les Entraînements du Club Tour</li>
          <li>La Coupe du Club Tour</li>
        </ol>
        <button className="btn rounded-0 bg-blue text-white">
          Inscrivez votre club
        </button>
      </div>
      <div className="py-5">
        <div className="container bg-white py-5">
          {Data.map((val, index) => (
            <div
              key={index}
              className={`row align-items-center ${
                index % 2 !== 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="col-md-6 px-5 py-5">
                <h1
                  className="text-blue fw-bold dispaly-4"
                  dangerouslySetInnerHTML={{ __html: val.title }}
                ></h1>
                <p
                  className="fs-17"
                  dangerouslySetInnerHTML={{
                    __html: val.para
                      .replace(/<br>/g, "<ul><li>")
                      .replace(/      /g, "</li></ul>"),
                  }}
                ></p>

                <button className="btn bg-blue text-white rounded-0 py-2 px-3">
                  {val.button}
                </button>
              </div>
              <div className="col-md-6 py-5">
                <img src={val.img} className="w-100" alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-md-7 mx-auto">
        <p className="fs-17">
          Le programme Club Tour est un moment-clé entre le Paris Saint-Germain
          et les clubs de football de l’ensemble de la région Île-de-France pour
          :
        </p>
        <ul>
          <li className="fs-17">
            <b>Transmettre</b> les valeurs sportives à la nouvelle génération de
            franciliens ;
          </li>
          <li className="fs-17">
            <b>Partager</b> la passion et renforcer nos liens historiques,
            essentiels et indéfectibles avec nos supporters actuels et futurs ;
          </li>
          <li className="fs-17">
            <b>Embrasser</b> les clubs d’Ile-de-France dans la dynamique du
            Paris Saint-Germain et promouvoir le football francilien.
          </li>
        </ul>
        <p className="fs-17">
          Fier du succès de ce programme depuis trois ans, le Paris
          Saint-Germain va continuer de renforcer ses liens avec les clubs et
          les jeunes footballeuses et footballeurs de la région parisienne pour
          s’ancrer davantage dans la région.
        </p>
        <h4 className="text-center">
          Plus d’informations sur le Club Tour, les modalités d’inscription et
          la gestion de votre candidature sur notre site dédié :
        </h4>
        <div className="py-4">
          <button className="btn text-light d-flex justify-content-center mx-auto bg-blue rounded-0">
            Inscrivez votre club
          </button>
        </div>
      </div>
    </>
  );
};

export default ClubTour;
