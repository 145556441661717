import React from "react";
import Navbar from "../../components/Navbar";
import ofkData from "./OfkData";

const AcademieOFK = () => {
  return (
    <>
      <Navbar />
      <div
        className="AcademieBanner "
        style={{
          backgroundImage: 'url("/images/dsc04128b.jpg")',
        }}
      >
        <div>
          <p className="text-blue">SAISON 2023-2024</p>
          <h3 className="fw-bold text-white">PSG TV Premium</h3>
          <p className="lead text-white">Le meilleur du PSG. En intégralité.</p>
          <button className="btn bg-blue rounded-0 py-2 text-light">
            S'abonner
          </button>
        </div>
      </div>
      <div className="py-5">
        <div className="container bg-white py-5">
          <h2 className="text-center">Les privilèges PSG TV Premium</h2>
          {ofkData.map((val, index) => (
            <div
              key={index}
              className={`row align-items-center ${
                index % 2 !== 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="col-md-6 px-5 py-5">
                <h1 className="text-center">{val.title}</h1>
                <p className="text-center">{val.para}</p>
              </div>
              <div className="col-md-6 py-5">
                <img src={val.img} className="w-100" alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AcademieOFK;
