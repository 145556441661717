import React from "react";
import Navbar from "../../components/Navbar";
// import img from "../../images/nasser-al-khelaifi-square.jpg";
// import img2 from "../../images/nasser-al-khelaifi-wide.jpg";

const Présidence = () => {
  return (
    <>
      <Navbar />
      <div className="col-md-7 mx-auto py-5">
        <h3 className="py-3">
          Nasser Al-Khelaïfi est l'une des personnalités influentes dans le
          monde du sport, du divertissement, des médias et des affaires.
        </h3>
        <div className="row">
          <div className="col-md-7">
            <p className="fs-17">
              Depuis 2011, M. Al-Khelaïfi est le président du Paris
              Saint-Germain Football Club (PSG), à la suite de sa nomination en
              tant que président de Qatar Sports Investments (QSI), laquelle a
              acquis la propriété du club de football français cette année-là.
              De plus, M. Al-Khelaïfi siège au conseil de la Ligue de Football
              Professionnel française (LFP).
            </p>
            <p className="fs-17">
              M. Al-Khelaïfi est membre du Comité exécutif de l'UEFA, ayant été
              réélu pour un second mandat, après avoir été élu président par
              l'Association européenne des clubs (ECA) en avril 2021,
              représentant plus de 500 clubs de football professionnels en
              Europe.
            </p>
            <p className="fs-17">
              En plus de ses rôles de premier plan dans le domaine du sport,
              Nasser Al-Khelaïfi est le président du groupe beIN MEDIA GROUP, un
              groupe international opérant dans plus de 40 pays dans les
              domaines du sport et du divertissement. C'est sous la direction de
              M. Al-Khelaïfi que beIN a acquis MIRAMAX, le célèbre studio
              hollywoodien de cinéma et de télévision, ainsi que Digiturk, la
              plus grande plateforme sportive et de divertissement en Turquie.
              M. Al-Khelaïfi est aujourd’hui le président de ces deux entités.
            </p>
            <p className="fs-17">
              Nasser Al-Khelaïfi est également membre du conseil
              d'administration du fonds souverain du Qatar, Qatar Investment
              Authority (QIA), tout en étant ministre d'État du Qatar.
            </p>
          </div>
          <div className="col-md-5">
            <img src="/images/nasser-al-khelaifi-square.jpg" alt="" className="w-100" />
            <div className="bg-light p-3">
              <h6 className="fw-bold">Nasser Al-Khelaïfi</h6>
              <p>Né le 12 novembre 1973 à Doha, Ad Dawdah (Qatar).</p>
              <p>→ Président du Paris Saint-Germain ;</p>
              <p>
                → Président du conseil d’administration de BeIn Media Group ;
              </p>
              <p>→ Membre de l’ECA de l’UEFA</p>
            </div>
          </div>
        </div>
        <p className="fs-17">
          Dans le monde des sports de raquette, Nasser Al-Khelaïfi est le
          président de la Fédération qatarie de tennis, squash et badminton, et
          supervise les deux plus grands tournois de tennis du pays (ATP et WTA)
          chaque année. M. Al-Khelaïfi est également président de Premier Padel,
          le principal circuit professionnel de padel, l'un des sports dont
          l’expansion est la plus rapide dans le monde. M. Al-Khelaïfia été un
          joueur de tennis professionnel classé au niveau mondial pendant de
          nombreuses années et a représenté son pays à la Coupe Davis.
        </p>
        <p className="fs-17">
          Nasser Al-Khelaïfi est un ambassadeur mondial des Youth Sports Games,
          fondés en 1996 pour permettre aux enfants de participer à des
          événements sportifs gratuits. Il est également le président de la
          Fondation PSG, qui soutient les enfants défavorisés et malades, ainsi
          que les jeunes et les communautés en difficulté. Depuis 2022, il est
          membre ami de l'Envoyé spécial du Secrétaire général des Nations Unies
          pour la sécurité routière.
        </p>
        <h4>
          « Paris est une des villes les plus incroyables du monde, et mon
          objectif en tant que Président a été de lui donner le club qu’elle
          mérite. Nous sommes aujourd’hui l’une des institutions sportives les
          plus reconnues au monde, sur et dehors des terrains, tout en gardant
          notre communauté locale au cœur de nos actions. Je suis fier de ce que
          nous avons accompli tous ensemble au PSG, en si peu de temps. Je suis
          très enthousiaste pour les années à venir, pour nos fans en France et
          à l’étranger. »
        </h4>
        <p className="fw-bold text-blue mb-0">NASSER AL-KHELAÏFI</p>
        <p>PRÉSIDENT-DIRECTEUR GÉNÉRAL DU PARIS SAINT-GERMAIN</p>
      </div>
      <img src="/images/nasser-al-khelaifi-wide.jpg" className="w-100" alt="" />
    </>
  );
};

export default Présidence;
