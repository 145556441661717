import React, { useState } from "react";
import NosEvenements from "./NosEvenements";
import NosProgrammes from "./NosProgrammes";

const Football = () => {
  const [activeButton, setActiveButton] = useState("nos-programmes");
  const [showStaff, setShowStaff] = useState(true); // Show NosProgrammes by default

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setShowStaff(buttonName === "nos-programmes"); // Show NosProgrammes if buttonName is "nos-programmes"
  };

  return (
    <>
      <div className="d-flex justify-content-center gap-3">
        {" "}
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "nos-programmes" ? "active" : ""
          }`}
          style={{ border: "1px solid #ccc" }}
          onClick={() => handleButtonClick("nos-programmes")}
        >
          Nos Programmes
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "nos-evenements" ? "active" : ""
          }`}
          style={{ border: "1px solid #ccc" }}
          onClick={() => handleButtonClick("nos-evenements")}
        >
          Nos événements
        </button>
      </div>
      {showStaff && <NosProgrammes />}
      {activeButton === "nos-evenements" && <NosEvenements />}
    </>
  );
};

export default Football;
