import React, { useState } from "react";
import Navbar from "../../components/Navbar";
// import img from "../../images/foundation-header-image.jpg";
import Vocation from "../../components/fondation/Vocation";
import ProgrammePhares from "../../components/fondation/ProgrammePhares";
import Partenaires from "../../components/fondation/Partenaires";
import Solidaire from "../../components/fondation/Solidaire";
import Contacter from "../../components/fondation/Contacter";
import Actualite from "../../components/fondation/Actualite";

const Fondation = () => {
  const [activeButton, setActiveButton] = useState("Vocation");
  const [showStaff, setShowStaff] = useState(true);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setShowStaff(buttonName === "Vocation");
  };

  return (
    <>
      <Navbar />
      <img src="/images/foundation-header-image.jpg" className="w-100" alt="" />
      {/* --- */}
      <div className="d-flex justify-content-center py-5">
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "Vocation" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("Vocation")}
        >
          NOTRE VOCATION
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "Actualite" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("Actualite")}
        >
          ACTUALITÉ
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "ProgrammePhares" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("ProgrammePhares")}
        >
          PROGRAMMES PHARES
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "Partenaires" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("Partenaires")}
        >
          PARTENAIRES
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "Solidaire" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("Solidaire")}
        >
          CANTINE SOLIDAIRE
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "Contacter" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("Contacter")}
        >
          NOUS CONTACTER
        </button>
      </div>
      {showStaff && <Vocation />}
      {activeButton === "Actualite" && <Actualite />}
      {activeButton === "ProgrammePhares" && <ProgrammePhares />}
      {activeButton === "Partenaires" && <Partenaires />}
      {activeButton === "Solidaire" && <Solidaire />}
      {activeButton === "Contacter" && <Contacter />}
    </>
  );
};

export default Fondation;
