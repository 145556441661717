import React from "react";
// import videoFileImg from "../../images/banner.webp";
import Navbar from "../../components/Navbar";
// import podcast1 from "../../images/podcast1.png";
// import podcast2 from "../../images/podcast2.png";
// import podcast3 from "../../images/podcast3.png";
// import podcast4 from "../../images/podcast4.png";
// import podcast5 from "../../images/podcast5.png";
// import podcast6 from "../../images/podcast6.png";
// import podcast7 from "../../images/podcast7.png";
// import podcast8 from "../../images/podcast8.png";
// import newpodcast1 from "../../images/newpodcast1.png";
// import newpodcast2 from "../../images/newpodcast2.png";
// import newpodcast3 from "../../images/newpodcast3.png";

const Podcasts = () => {
  const Data = [
    {
      title: "Le Monde des Rouge et Bleu",
      date: "Thursday, December 14, 2023",
      img: "/images/podcast1.png",
    },
    {
      title: "Histoires de Parisiens",
      date: "Tuesday, March 12, 2024",
      img: "/images/podcast2.png",
    },
    {
      title: "Onze Titres pour l'Histoire",
      date: " Tuesday, October 10, 2023",
      img: "/images/podcast3.png",
    },
    {
      title: "Paris est Magique",
      date: "Wednesday, August 9, 2023",
      img: "/images/podcast4.png",
    },
    {
      title: "Paris est Magique - English Version",
      date: " Wednesday, August 9, 2023",
      img: "/images/podcast5.png",
    },
    {
      title: "PSG Academy",
      date: " Thursday, February 16, 2023",
      img: "/images/podcast6.png",
    },
    {
      title: "La conf'",
      date: " Saturday, March 16, 2024",
      img: "/images/podcast7.png",
    },
    {
      title: "Paris est Magique - versión español",
      date: " Wednesday, April 19, 2023",
      img: "/images/podcast8.png",
    },
  ];
  return (
    <>
      <Navbar />
      <div className="bg-blue">
        <div className="container py-5">
          <h2 className="fw-bold text-white">PSG PODCAST</h2>
          <h5 className=" text-white">
            Histoire(s), passions, émotions, actualités… Écoutez les programmes
            audio officiels du Paris Saint-Germain
          </h5>
          <div className="row py-3">
            <div className="col-md-4">
              <div className="bg-white p-3 h-100">
                <video
                  className="w-100 "
                  controls
                  autoplay
                  loop
                  muted
                  poster="newpodcast1.png"
                  style={{ height: "15rem", objectFit: "fill" }}
                >
                  <source src="/images/video-banner.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <p className="mb-0 fs-17 fw-bold">
                  Ligue 1 / 26e journée / Montpellier HSC - Paris Saint-Germain
                </p>
                <p className="mb-0">La conf'</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bg-white p-3 h-100">
                <video
                  className="w-100 "
                  controls
                  autoplay
                  loop
                  muted
                  poster="newpodcast2.png"
                  style={{ height: "15rem", objectFit: "fill" }}
                >
                  <source src="/images/video-banner.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <p className="mb-0 fs-17 fw-bold">
                  Pierre DUCROCQ - Un titi dans l'âme
                </p>
                <p className="mb-0">Histoires de Parisiens</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bg-white p-3 h-100">
                <video
                  className="w-100 "
                  controls
                  autoplay
                  loop
                  muted
                  poster="newpodcast3.png"
                  style={{ height: "15rem", objectFit: "fill" }}
                >
                  <source src="/images/video-banner.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <p className="mb-0 fs-17 fw-bold">
                  Coupe de France / Quart de finale / Paris Saint-Germain - OGC
                  Nice
                </p>
                <p className="mb-0">La conf'</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <p className="fs-17 fw-bold mt-5">Podcast 8</p>
        <div className="row">
          {Data.map((val, index) => (
            <div className="col-md-3 mb-4">
              <div className="bg-white p-2 h-100">
                <video
                  className="w-100 object-fit-cover"
                  controls
                  loop
                  poster={val.img}
                  style={{ height: "10rem" }}
                >
                  <source src="/images/video-banner.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <p className="mb-0 fs-17 fw-bold">{val.title}</p>
                <p className="mb-0">{val.date}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Podcasts;
