// import img from "../../images/banner.png";

const cardsData = [
  {
    id: "1",
    img: "/images/banner.png",
    // img: img,
    category: "ÉQUIPE PREMIÈRE",
    type: "ARTICLES",
    date: "23 mars 2024",
    title: "La France U23 de Barcola s'impose face à la Côte d'Ivoire",
    desc: "                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia nemo harum officiis pariatur dolorem ab assumenda dolor, labore reprehenderit, amet optio qui iure unde deleniti, quibusdam adipisci doloremque sequi. Laudantium?c Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia nemo harum officiis pariatur dolorem ab assumenda dolor, labore reprehenderit, amet optio qui iure unde deleniti, quibusdam adipisci doloremque sequi. Laudantium?",
  },
  {
    id: "2",
    img: "/images/banner.png",
    // img: img,
    category: "ÉQUIPE PREMIÈRE",
    date: "23 mars 2024",
    type: "ARTICLES",
    title: "La France U23 de Barcola s'impose face à la Côte d'Ivoire",
    desc: "                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia nemo harum officiis pariatur dolorem ab assumenda dolor, labore reprehenderit, amet optio qui iure unde deleniti, quibusdam adipisci doloremque sequi. Laudantium?c Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia nemo harum officiis pariatur dolorem ab assumenda dolor, labore reprehenderit, amet optio qui iure unde deleniti, quibusdam adipisci doloremque sequi. Laudantium?",
  },
  {
    id: " 3",
    img: "/images/banner.png",
    // img: img,
    category: "ÉQUIPE PREMIÈRE",
    date: "23 mars 2024",
    type: "ARTICLES",
    title: "La France U23 de Barcola s'impose face à la Côte d'Ivoire",
    desc: "                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia nemo harum officiis pariatur dolorem ab assumenda dolor, labore reprehenderit, amet optio qui iure unde deleniti, quibusdam adipisci doloremque sequi. Laudantium?c Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia nemo harum officiis pariatur dolorem ab assumenda dolor, labore reprehenderit, amet optio qui iure unde deleniti, quibusdam adipisci doloremque sequi. Laudantium?",
  },
  {
    id: "4",
    img: "/images/banner.png",
    // img: img,
    category: "EQUIPE PREMIÈRE",
    date: "23 mars 2024",
    type: "DIAPORAMAS",
    title: "La France U23 de Barcola s'impose face à la Côte d'Ivoire",
    desc: "                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia nemo harum officiis pariatur dolorem ab assumenda dolor, labore reprehenderit, amet optio qui iure unde deleniti, quibusdam adipisci doloremque sequi. Laudantium?c Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia nemo harum officiis pariatur dolorem ab assumenda dolor, labore reprehenderit, amet optio qui iure unde deleniti, quibusdam adipisci doloremque sequi. Laudantium?",
  },
  {
    id: "5",
    img: "/images/banner.png",
    // img: img,
    category: "ÉQUIPE PREMIÈRE",
    date: "23 mars 2024",
    type: "VIDÉOS",
    title: "La France U23 de Barcola s'impose face à la Côte d'Ivoire",
    desc: "                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia nemo harum officiis pariatur dolorem ab assumenda dolor, labore reprehenderit, amet optio qui iure unde deleniti, quibusdam adipisci doloremque sequi. Laudantium?c Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia nemo harum officiis pariatur dolorem ab assumenda dolor, labore reprehenderit, amet optio qui iure unde deleniti, quibusdam adipisci doloremque sequi. Laudantium?",
  },
];
export default cardsData;
