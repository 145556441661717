import React from "react";
// import img1 from "../../../images/academie-football-ouvertes.jpg";
// import img2 from "../../../images/academie-football-methodologie.jpg";
// import img3 from "../../../images/academie-football-environnement.jpg";
// import img4 from "../../../images/proresidency-training.jpg";
// import img5 from "../../../images/dsc_9462.jpg";

const NosProgrammes = () => {
  const Data1 = [
    {
      id: "1",
      img: "/images/academie-football-ouvertes.jpg",
      // img: img1,
      title: "Des écoles ouvertes à tous",
      para: "La Paris Saint-Germain Academy est ouverte aux filles et aux garçons, de 4 à 17 ans, sans sélection de niveau, pour partager les valeurs du Paris Saint-Germain au travers d’expériences culturelles et sportives uniques.",
    },
    {
      id: "2",
      img: "/images/academie-football-methodologie.jpg",
      // img: img2,
      title: "Une méthodologie reconnue",
      para: "Chaque coach est formé aux méthodes du Paris Saint-Germain, pour garantir un apprentissage de qualité.<br> Les séances d’entraînement sont conçues par la Direction Technique du club en suivant la philosophie de jeu du Paris Saint Germain, tout en les adaptant au niveau de chaque enfant.",
    },
    {
      id: "3",
      img: "/images/academie-football-environnement.jpg",
      // img: img3,
      title: "Un environnement premium      ",
      para: "Les infrastructures proposées correspondent à des standards haut de gamme favorisant l’épanouissement des enfants, dans un environnement sécurisé.",
    },
  ];
  const Data2 = [
    {
      id: "1",
      img: "/images/proresidency-training.jpg",
      // img: img4,
      title: "Un programme intensif et immersif",
      para: "Faites l'expérience d'un environnement totalement immersif et découvrez le mode de vie d'un footballeur de haut niveau, en pension complète sur un campus haut de gamme et tout équipé.",
    },
    {
      id: "2",
      img: "/images/dsc_9462.jpg",
      // img: img5,
      title: "Un accompagnement sportif et universitaire",
      para: "Les PSG Academy Pro Residency offrent un programme Académique de haut niveau, avec différentes options de cursus francophones, anglophones, en présentiel ou à distance.Un double-programme conçu pour donner aux jeunes toutes les clés pour préparer leur futur dans les meilleures conditions",
    },
  ];
  return (
    <>
      <div className="col-md-7 mx-auto">
        <h4 className="text-center py-5">
          Créée en 2005, la Paris Saint-Germain Academy permet à des milliers
          d’enfants, en France et dans le monde, de pratiquer leur passion dans
          ses écoles de football, à l’année ou à travers des stages.
        </h4>
      </div>
      <div className="py-5">
        <div className="container bg-white py-5">
          {Data1.map((val, index) => (
            <div
              key={index}
              className={`row align-items-center ${
                index % 2 !== 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="col-md-6 px-5 py-5">
                <h1 className="text-blue fw-bold dispaly-4">{val.title}</h1>
                <p className="fs-17">{val.para}</p>
              </div>
              <div className="col-md-6 py-5">
                <img src={val.img} className="w-100" alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="py-5 mx-auto col-md-6">
        <h3 className="fw-bold text-center mb-3">Academy Pro Residency</h3>
        <p className="fs-17 text-center">
          Avec des entraînements quotidiens et des compétitions, le programme
          annuel permet à chaque joueur de bénéficier d’un accompagnement
          sportif optimal sur et en dehors du terrain. La PSG Academy déploie
          maintenant ses programmes Pro Residency dans 3 centres à travers le
          monde : Evian (France), Fort Lauderdale (Floride, USA) et Saly
          (Sénégal).
        </p>
      </div>
      <div className="py-5">
        <div className="container bg-white py-5">
          {Data2.map((val, index) => (
            <div
              key={index}
              className={`row align-items-center ${
                index % 2 !== 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="col-md-6 px-5 py-5">
                <h1 className="text-blue fw-bold dispaly-4">{val.title}</h1>
                <p className="fs-17">{val.para}</p>
              </div>
              <div className="col-md-6 py-5">
                <img src={val.img} className="w-100" alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NosProgrammes;
