import React from "react";
// import img1 from "../../../images/debutants.jpeg";
// import img2 from "../../../images/ethique.jpg";
// import img3 from "../../../images/stages2.jpeg";
// import img4 from "../../../images/supports.jpg";

const Esports = () => {
  const Data = [
    {
      id: "1",
      img: "/images/debutants.jpeg",
      // img: img1,
      title: "Destinée à tous les joueurs, débutants ou confirmés",
      para: "La Paris Saint-Germain Academy Esports offre une opportunité unique à tous les passionnés de gaming de progresser grâce à la méthodologie d’entrainement du club. Réputé mondialement pour la qualité de sa formation et son savoir-faire en termes de performance, le club met au cœur de son projet de formation la transmission de valeurs éducatives fortes.",
    },
    {
      id: "2",
      img: "/images/ethique.jpg",
      // img: img2,
      title: "Une pratique éthique et responsable",
      para: "Au-delà de les faire progresser sur le plan de la performance, l’objectif est également de promouvoir auprès des jeunes gamers une pratique éthique et responsable du jeu vidéo.Tous les coachs du programme Paris Saint-Germain Academy Esports sont sensibilisés aux bons comportements, tels que le contrôle du temps passé ou les limites à se fixer, afin de jouer dans des conditions idéales.",
    },
    {
      id: "3",
      img: "/images/stages2.jpeg",
      // img: img3,
      title: "Cours et stages au studio Paris Saint-Germain",
      para: "Situé à Boulogne-Billancourt, Le Studio Paris Saint-Germain accueille différents types de stages et de sessions d’entraînement destinés à des publics variés.Ainsi, enfants ou adultes, débutants ou confirmés, tous les fans de gaming et du Paris Saint-Germain auront l’opportunité de venir s’entraîner dans un cadre exceptionnel en étant accompagnés par coachs professionnels.",
    },
    {
      id: "4",
      img: "/images/supports.jpg",
      // img: img4,
      title: "Différents formats et supports",
      para: "Des stages 100% Esports sont proposés sur différents jeux et différents supports (PC ou consoles), et sur des formats allant du récréationnel (pratique loisir) au compétitif (recherche de performance).      ",
    },
  ];
  return (
    <>
      <div className="col-md-7 mx-auto">
        <div className="py-5">
          <h4 className=" fw-bold">
            La Paris Saint-Germain Academy eSports symbolise tout l’intérêt
            porté par le club au monde de l’eSport et au développement de sa
            formation.
          </h4>
          <h4 className="">
            Ce programme a pour but d’enseigner la pratique de l'Esport à
            travers des cours et sessions d’entraînements sur différents
            jeux-vidéos. Ces cours sont dispensés lors de stages organisés dans
            le Studio Paris Saint-Germain, en région parisienne.
          </h4>
        </div>
      </div>
      <div className="py-5">
        <div className="container bg-white py-5">
          {Data.map((val, index) => (
            <div
              key={index}
              className={`row align-items-center ${
                index % 2 !== 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="col-md-6 px-5 py-5">
                <h1 className="text-blue fw-bold dispaly-4">{val.title}</h1>
                <p className="fs-17">{val.para}</p>
              </div>
              <div className="col-md-6 py-5">
                <img src={val.img} className="w-100" alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-md-6 mx-auto">
        <video
          className="w-100"
          controls
          autoplay
          loop
          muted
          // poster={videoFileImg}
        >
          <source src="/images/video-banner.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
};

export default Esports;
