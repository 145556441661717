import React from "react";
// import img1 from "../../../images/worldcup.jpg";
// import img2 from "../../../images/academy-football-selection-v22.jpg";

const NosEvenements = () => {
  const Data2 = [
    {
      id: "1",
      img: "/images/worldcup.jpg",
      // img: img1,
      title: "Paris Saint-Germain Academy World Cup      ",
      para: "Elle rassemble pendant 4 jours des équipes de chaque Academy, leur permettant de vivre une expérience culturelle et sportive unique. Au programme : Tournoi, visites de Paris et match de Ligue 1 au Parc des Princes ainsi que de nombreuses surprises.",
    },
    {
      id: "2",
      img: "/images/academy-football-selection-v22.jpg",
      // img: img2,
      title: "Paris Saint-Germain Academy Selection Camp",
      para: "Ce stage intensif d’une semaine rassemble les deux meilleurs joueurs de chacune de nos académies, dans le but de les accompagner dans leur développement, et de les sensibiliser sur les exigences du football de haut niveau.",
    },
  ];
  return (
    <>
      <div className="col-md-7 mx-auto">
        <h4 className="text-center py-5">
          Afin d’entretenir un lien fort avec le club et d’offrir aux enfants
          des moments de vie inoubliables, deux évènements annuels majeurs
          rassemblent à Paris des jeunes provenant de toutes nos Académies dans
          le monde.
        </h4>
      </div>
      <div className="py-5">
        <div className="container bg-white py-5">
          {Data2.map((val, index) => (
            <div
              key={index}
              className={`row align-items-center ${
                index % 2 !== 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="col-md-6 px-5 py-5">
                <h1 className="text-blue fw-bold dispaly-4">{val.title}</h1>
                <p className="fs-17">{val.para}</p>
              </div>
              <div className="col-md-6 py-5">
                <img src={val.img} className="w-100" alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NosEvenements;
