// import img frombanner.png "../../images/banner.png";

const videoData = [
  {
    id: "1",
    img: "/images/banner.png",
    category: "HANDBALL",
    date: "28 mars 2024",
    title: "Les Parisiens sont à Plock",
    para: "Plongez dans les coulisses du match entre les équipes féminines du Paris Saint-Germain et du BK Häcken FF.",
    video: "/images/video-banner.mp4",
  },
  {
    id: "2",
    img: "/images/banner.png",
    category: " FÉMININES",
    date: "  27 mars 2024",
    title:
      "Veille de Champions League avec nos féminines avant d'affronter Häcken",
    para: "Plongez dans les coulisses du match entre les équipes féminines du Paris Saint-Germain et du BK Häcken FF.",
    video: "/images/video-banner.mp4",
  },
  {
    id: " 3",
    img: "/images/banner.png",
    category: "CLUB",
    date: "27 mars 2024",
    title: "Un jour au Campus - Épisode 2",
    para: "Plongez dans les coulisses du match entre les équipes féminines du Paris Saint-Germain et du BK Häcken FF.",
    video: "/images/video-banner.mp4",
  },
  {
    id: "4",
    img: "/images/banner.png",
    category: "FÉMININES ",
    date: "26 mars 2024",
    title: "Korbin Albert évoque la réception du BK Häcken au Parc des Princes",
    para: "Plongez dans les coulisses du match entre les équipes féminines du Paris Saint-Germain et du BK Häcken FF.",
    video: "/images/video-banner.mp4",
  },
  {
    id: "5",
    img: "/images/banner.png",
    category: "CLUB",
    date: "26 mars 2024",
    title: "Un jour au Campus - Épisode 1",
    para: "Plongez dans les coulisses du match entre les équipes féminines du Paris Saint-Germain et du BK Häcken FF.",
    video: "/images/video-banner.mp4",
  },
];
export default videoData;
