import React from "react";
// import img from "../../images/ecolerougeetbleu.png";
// import img2 from "../../images/cantinesolidaire.png";
// import img3 from "../../images/allezlesfilles.png";
// import img4 from "../../images/onzedelegende.png";

const ProgrammePhares = () => {
  const Data = [
    {
      id: "1",
      img: "/images/ecolerougeetbleu.png",
      // img: img,
      title: "Les Entraînements du Club Tour",
      para: "  Accompagner les enfants en difficulté avec un programme « Après l'école » axé sur une démarche pédagogique et sportive innovante. <br><br>  Apprendre autrement : Convaincus que le sport est un levier d’apprentissage efficace pour guider les enfants vers la réussite, l'École ROUGE & BLEU guide les enfants vers la réussite. <br><br> 1 300 enfants accompagnés chaque année depuis la création du programme périscolaire en 2016.      ",
      button: "Voir la vidéo du Onze de Légende",
    },
    {
      id: "2",
      img: "/images/cantinesolidaire.png",
      // img: img2,
      title: "Cantine solidaire",
      para: " Distribution de dénrées alimentaires au Parc des Princes pour les étudiants en grande précarité. <br><br> Le programme Cantine Solidaire s’appuie sur une collaboration solide avec les associations Studhelp, Cop1, Aïda, TrouveTaVoie et la Communauté Ecotable.  <br><br>  Cette initiative témoigne de la volonté indéfectible de la Fondation du Paris Saint-Germain de répondre aux besoins essentiels des étudiants qui traversent des difficultés, afin de leur assurer un quotidien plus serein. <br><br>  6 éditions depuis 2021 ont permis de proposer un repas chaud et des animations chaleureuses à1 000 étudiants en situation de précarité.",
      button: "Voir la vidéo du Onze de Légende",
    },
    {
      id: "3",
      img: "/images/allezlesfilles.png",
      // img: img3,
      title: "Allez les filles !",
      para: "Initier les jeunes filles à la pratique sportive et les sensibiliser durablement aux bienfaits et valeurs du sport. <br><br>50 jeunes filles participent au programme annuel « Allez les filles », conçu sur-mesure pour les initier à la pratique sportive. Marrainées par les joueuses du Paris Saint-Germain, elles s’ouvrent à de nouveaux horizons par la découverte sportive et culturelle et développent leur confiance en elles. <br><br> 550 jeune filles participantes depuis 2012",
      button: "Voir la vidéo du Onze de Légende",
    },
    {
      id: "3",
      img: "/images/onzedelegende.png",
      // img: img4,
      title: "Onze de légende",
      para: "Programme phare du pole handicap de la Fondation Paris Saint-Germain pour promouvoir l'inclusion <br><br> 1 équipe constituée d’enfants atteints d’autisme et d’enfants neurotypiques. 1 fois par semaine, les enfants s’entrainent à Clamart dans les mêmes conditions que les équipes de football adapté de la Fondation. Cet entrainement permet aux enfants de développer leur coordination motrice et leur habileté physique et d’améliorer leur confiance en eux. Le football offre la possibilité de renforcer des compétences sociales pour surmonter les difficultés à communiquer et à interagir avec les autres. <br><br> 15 000 jeunes et enfants accompagnés chaque saison. 400 opérations annuelles.",
      button: "Voir la vidéo du Onze de Légende",
    },
  ];
  return (
    <>
      <div className="py-5">
        <div className="container bg-white py-5">
          {Data.map((val, index) => (
            <div
              key={index}
              className={`row align-items-center ${
                index % 2 !== 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="col-md-6 px-5 py-5">
                <h1 className="text-blue fw-bold dispaly-4">{val.title}</h1>
                <p
                  className="fs-17"
                  dangerouslySetInnerHTML={{ __html: val.para }}
                ></p>

                <button className="btn bg-blue text-white rounded-0 py-2 px-3">
                  {val.button}
                </button>
              </div>
              <div className="col-md-6 py-5">
                <img src={val.img} className="w-100" alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-md-7 mx-auto pb-5">
        <h3 className="fw-bold text-center py-5">
          2024 : L’ÉCOLE ROUGE & BLEU À NECKER
        </h3>
        <div className="my-5">
          <p className="lead">
            Une initiative pionnière au plus près des enfants :
          </p>
          <p className="fs-17">
            Fort de son partenariat avec l’hôpital Necker depuis plus de 15 ans,
            la Fondation va construire une Ecole Rouge & Bleu au sein de
            l’hôpital Necker-Enfants Malades pour favoriser la mixité entre les
            enfants et l’inclusion par le sport. Il s’agit d’une expérience
            unique permettant de faire tomber les barrières et d’ouvrir
            l’Hôpital sur la Ville.
          </p>
        </div>
        <p className="lead">
          Notre partenariat historique avec l’hôpital a un objectif primordial :
          offrir des moments de joie et d’évasion aux enfants hospitalisés et au
          personnel de l’hôpital. L’installation d’une Ecole Rouge & Bleu au
          sein même de l’hôpital Necker-Enfants malades, permettra de proposer
          une combinaison d’activités sportives ludiques et pédagogiques adaptée
          aux contraintes médicales des enfants. Cette école sera un phare
          d’espoir pour ceux qui en ont le plus besoin.
        </p>
        <p className="fw-bold text-blue mb-0">FABIEN ALLÈGRE</p>
        <p>
          DIRECTEUR ADJOINT DE LA FONDATION PARIS SAINT-GERMAIN ET
          VICE-PRÉSIDENT DU FONDS DE DOTATION PARIS SAINT-GERMAIN
        </p>
      </div>
    </>
  );
};

export default ProgrammePhares;
