import React, { useState } from "react";
import Staff from "../../components/home/effectif/Staff";
import Effectif from "../../components/home/effectif/Effectif";
import Navbar from "../../components/Navbar";

const EffectifMain = () => {
  const [activeButton, setActiveButton] = useState("Effectif");
  const [showStaff, setShowStaff] = useState(false);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setShowStaff(buttonName === "Staff");
  };

  return (
    <>
      <Navbar />
      <div className="bg-light py-5">
        <div className="d-flex justify-content-center gap-5">
          <div className="d-flex" style={{ border: "1px solid #ccc" }}>
            <button
              className={`active_btn btn py-2 px-3 ${
                activeButton === "Effectif" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("Effectif")}
            >
              Effectif
            </button>
            <button
              className={`active_btn btn py-2 px-3 ${
                activeButton === "Staff" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("Staff")}
            >
              Staff
            </button>
          </div>
          {/* <select name="" id="">
            <option value="">2023/2024</option>
            <option value="">2023/2024</option>
            <option value="">2023/2024</option>
            <option value="">2023/2024</option>
          </select> */}
        </div>
      </div>
      {showStaff && <Staff />}
      {activeButton === "Effectif" && <Effectif />}
    </>
  );
};

export default EffectifMain;
