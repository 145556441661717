import React from "react";
import Navbar from "../../../components/Navbar";
// import img5 from "../../../images/jobs-rejoindre-22-tb.jpg";

const NousRejoindre = () => {
  return (
    <>
      <Navbar />
      <div className=" position-relative">
        <img
          src="/images/jobs-rejoindre-22-tb.jpg"
          // src={img5}
          alt=""
          className="object-fit-cover w-100"
          style={{ height: "25rem" }}
        />
        <h2 className="position-absolute fw-bold start-50 bottom-50 text-light">
          Nous rejoindre
        </h2>
      </div>
      <div className="col-md-7 mx-auto py-5">
        <h3>Rejoignez la famille Paris Saint-Germain!</h3>
        <h6 className="fw-bold text-blue">OFFRES D'EMPLOI</h6>
        <p className="fs-17">
          Retrouvez sur notre page LinkedIn l'ensemble des offres d’emploi (CDI,
          CDD, stages ou alternances) proposées par le Club.
        </p>
        <p className="fs-17">
          Le Paris Saint-Germain tient à développer l’inclusion de chacun dans
          le monde professionnel. Fiers des engagements portés par l’ensemble de
          ses collaborateurs, le Club s'inscrit dans une politique de diversité
          et d’insertion, notamment en faveur des personnes en situation de
          handicap.
        </p>
        <button className="btn bg-blue text-light py-2 rounded-0">
          Découvrir les offres
        </button>
        <h6 className="fw-bol text-blue pt-4 fw-bold">CANDIDATURE MATCHDAY</h6>
        <p className="fs-17">
          Nous recherchons des personnes motivées et disponibles pour participer
          à l’organisation opérationnelles lors de nos différents événements.
        </p>
        <button className="btn bg-blue text-light py-2 rounded-0">
          Candidater
        </button>
      </div>
    </>
  );
};

export default NousRejoindre;
