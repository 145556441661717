import React from "react";
// import { MdCreditCard } from "react-icons/md";\
import Navbar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import photosData from "./PhotosMedia";

const PhotosDetail = () => {
  const { id } = useParams();
  // let newData = photosData.find((val) => val.id === id);
  const newData = photosData.find((val) => val.id === id);

  return (
    <>
      <Navbar />
      <img src={newData.img} className="w-100" alt="" />
      <div className="pb-5">
        <div className="container shadow mt-5 p-4">
          <h1 className="fw-bold display-5">{newData.title}</h1>
          <div className="d-flex gap-2 py-3">
            <span className="text-blue fw-bold">{newData.category}</span>
            <span className="text-secondary fw-bold">|</span>
            <span className="text-secondary fw-bold">{newData.date}</span>
          </div>
          <p className="fs-17">{newData.desc}</p>
          <p className="fs-17">{newData.para}</p>
          <div className="row">
            <div className="col-md-4">
              <img src={newData.img1} className="w-100 mb-3" alt="" />
            </div>
            <div className="col-md-4">
              <img src={newData.img2} className="w-100 mb-3" alt="" />
            </div>
            <div className="col-md-4">
              <img src={newData.img3} className="w-100 mb-3" alt="" />
            </div>
            <div className="col-md-4">
              <img src={newData.img4} className="w-100 mb-3" alt="" />
            </div>
            <div className="col-md-4">
              <img src={newData.img5} className="w-100 mb-3" alt="" />
            </div>
            <div className="col-md-4">
              <img src={newData.img6} className="w-100 mb-3" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotosDetail;
