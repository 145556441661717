import React from "react";
// import img1 from "../../../images/americanexpress.png";
// import img2 from "../../../images/qatar-airways.png";
// import img3 from "../../../images/bilibili.png";
// import img4 from "../../../images/jissbon.png";
// import img5 from "../../../images/steelseries-blue-v22.png";
// import img6 from "../../../images/amd-logo.png";
// import img7 from "../../../images/hubber.png";

const Esports = () => {
  return (
    <>
      <div className="container">
        <p className="fw-bold fs-17 mt-5">PSG EA SPORTS FC</p>
        <div className="row">
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/americanexpress.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/qatar-airways.png" width={120} alt="" />
            </a>
          </div>
        </div>
        <p className="fw-bold fs-17 mt-5">PSG.LGD</p>
        <div className="row">
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/bilibili.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/jissbon.png" width={120} alt="" />
            </a>
          </div>
        </div>
        <p className="fw-bold fs-17 mt-5">PSG TALON</p>
        <div className="row">
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/steelseries-blue-v22.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/amd-logo.png" width={120} alt="" />
            </a>
          </div>
        </div>
        <p className="fw-bold fs-17 mt-5">PSG ARENA OF VALOR</p>
        <div className="row">
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/hubber.png" width={120} alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Esports;
