// import img1 from "../../images/montpellier.png";
// import img2 from "../../images/le-havre.png";

const data = [
  {
    category: "AMICAL",
    date: "VENDREDI 21 JUILLET 2023",
    team1: "PARIS SAINT-GERMAIN",
    team2: "LE HAVRE AC",
    logo1: "/images/montpellier.png",
    logo2: "/images/le-havre.png",
    point1: "2",
    point2: "0",
    location: "CAMPUS PSG (POISSY)",
  },
  {
    category: "AMICAL",
    date: "VENDREDI 21 JUILLET 2023",
    team1: "PARIS SAINT-GERMAIN",
    team2: "LE HAVRE AC",
    logo1: "/images/montpellier.png",
    logo2: "/images/le-havre.png",
    point1: "2",
    point2: "0",
    location: "CAMPUS PSG (POISSY)",
  },
  {
    category: "TROPHÉE DES CHAMPIONS",
    date: "VENDREDI 21 JUILLET 2023",
    team1: "PARIS SAINT-GERMAIN",
    team2: "LE HAVRE AC",
    logo1: "/images/montpellier.png",
    logo2: "/images/le-havre.png",
    point1: "2",
    point2: "0",
    location: "CAMPUS PSG (POISSY)",
  },
  {
    category: "TROPHÉE DES CHAMPIONS",
    date: "VENDREDI 21 JUILLET 2023",
    team1: "PARIS SAINT-GERMAIN",
    team2: "LE HAVRE AC",
    logo1: "/images/montpellier.png",
    logo2: "/images/le-havre.png",
    point1: "2",
    point2: "0",
    location: "CAMPUS PSG (POISSY)",
  },
  {
    category: "COUPE DE FRANCE",
    date: "VENDREDI 21 JUILLET 2023",
    team1: "PARIS SAINT-GERMAIN",
    team2: "LE HAVRE AC",
    logo1: "/images/montpellier.png",
    logo2: "/images/le-havre.png",
    point1: "2",
    point2: "0",
    location: "CAMPUS PSG (POISSY)",
  },
  {
    category: "AMICAL",
    date: "VENDREDI 21 JUILLET 2023",
    team1: "PARIS SAINT-GERMAIN",
    team2: "LE HAVRE AC",
    logo1: "/images/montpellier.png",
    logo2: "/images/le-havre.png",
    point1: "2",
    point2: "0",
    location: "CAMPUS PSG (POISSY)",
  },
  {
    category: "COUPE DE LA LIGUE",
    date: "VENDREDI 21 JUILLET 2023",
    team1: "PARIS SAINT-GERMAIN",
    team2: "LE HAVRE AC",
    logo1: "/images/montpellier.png",
    logo2: "/images/le-havre.png",
    point1: "2",
    point2: "0",
    location: "CAMPUS PSG (POISSY)",
  },
  {
    category: "AMICAL",
    date: "VENDREDI 21 JUILLET 2023",
    team1: "PARIS SAINT-GERMAIN",
    team2: "LE HAVRE AC",
    logo1: "/images/montpellier.png",
    logo2: "/images/le-havre.png",
    point1: "2",
    point2: "0",
    location: "CAMPUS PSG (POISSY)",
  },
  {
    category: "UEFA CHAMPIONS LEAGUE",
    date: "VENDREDI 21 JUILLET 2023",
    team1: "PARIS SAINT-GERMAIN",
    team2: "LE HAVRE AC",
    logo1: "/images/montpellier.png",
    logo2: "/images/le-havre.png",
    point1: "2",
    point2: "0",
    location: "CAMPUS PSG (POISSY)",
  },
  {
    category: "UEFA CHAMPIONS LEAGUE",
    date: "VENDREDI 21 JUILLET 2023",
    team1: "PARIS SAINT-GERMAIN",
    team2: "LE HAVRE AC",
    logo1: "/images/montpellier.png",
    logo2: "/images/le-havre.png",
    point1: "2",
    point2: "0",
    location: "CAMPUS PSG (POISSY)",
  },

  {
    category: "LIGUE 1",
    date: "VENDREDI 21 JUILLET 2023",
    team1: "PARIS SAINT-GERMAIN",
    team2: "LE HAVRE AC",
    logo1: "/images/montpellier.png",
    logo2: "/images/le-havre.png",
    point1: "2",
    point2: "0",
    location: "CAMPUS PSG (POISSY)",
  },

  {
    category: "LIGUE 1",
    date: "VENDREDI 21 JUILLET 2023",
    team1: "PARIS SAINT-GERMAIN",
    team2: "LE HAVRE AC",
    logo1: "/images/montpellier.png",
    logo2: "/images/le-havre.png",
    point1: "2",
    point2: "0",
    location: "CAMPUS PSG (POISSY)",
  },
];
export default data;
