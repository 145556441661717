import React from "react";
// import logo from "../images/logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-light bg-white ">
        <div class="container-fluid px-5">
          <Link to={"/"} class="navbar-brand">
            <img src="/images/logo.png" width={120} alt="" />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" href="##">
                  <div class="dropdown">
                    <p className="mb-0">ÉQUIPE PREMIÈRE</p>
                    <div class="dropdown-content">
                      <p>
                        <Link to={"/actualite"}>Actualité</Link>
                      </p>
                      <p>
                        <Link to={"/effectif"}>Effectif</Link>
                      </p>
                      <p>
                        <Link to={"/calendrier"}>Calendrier et résultats</Link>
                      </p>
                      <p>
                        <Link to={"/classement"}>Classement</Link>
                      </p>
                      <p>
                        <Link to={"/palmares"}>Palmarès</Link>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="##">
                  <div class="dropdown">
                    <p className="mb-0">MÉDIAS</p>
                    <div class="dropdown-content">
                      <p>
                        <Link to={"/ofktv"}>OFK TV</Link>
                      </p>
                      <p>
                        <Link to={"/academie"}>Acadèmie OFK TV </Link>
                      </p>
                      <p>
                        <Link to={"/photos"}>Photos</Link>
                      </p>
                      <p>
                        <Link to={"/podcast"}>Podcasts</Link>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="##">
                  <div class="dropdown">
                    <p className="mb-0">LE CLUB & L'ACADEMIE</p>
                    <div class="dropdown-content">
                      <p>
                        <a href="##">Acadèmie OFK</a>
                      </p>{" "}
                      <p>
                        <a href="##">Vision de I'Academie </a>
                      </p>
                      <p>
                        <Link to={"/presidence"}>Présidence</Link>
                      </p>
                      <p>
                        <Link to={"/partenaires"}>Partenaires</Link>
                      </p>
                      <p>
                        <Link to={"/carrieres"}>Carrières</Link>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="##">
                  <div class="dropdown">
                    <p className="mb-0">AUTOUR DU CLUB</p>
                    <div class="dropdown-content">
                      <p>
                        <Link to={"/fondation"}>
                          Fondation & Fonds de Dotation
                        </Link>
                      </p>
                      <p>
                        <Link to={"/academy"}>Academy</Link>
                      </p>
                      <p>
                        <Link to={"/clubtour"}>Club tour</Link>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="##">
                  <div class="dropdown">
                    <p className="mb-0">SHOP</p>
                    <div class="dropdown-content">
                      <p>
                        <Link to={"/boutique"}>Boutique</Link>
                      </p>
                      <p>
                        <Link to={"/maillotsportés"}>Maillots portés</Link>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <div class="d-flex gap-2">
              <Link
                to={"/login"}
                class="btn bg-blue text-light rounded-0 px-5 py-3"
                type="submit"
              >
                Login
              </Link>
              <Link
                to={"/signup"}
                class="btn bg-blue text-light rounded-0 px-5 py-3"
                type="submit"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
