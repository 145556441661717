import React from "react";
import Navbar from "../../../components/Navbar";
// import img3 from "../../../images/jobs-etudiants.jpg";
const EtudiantsDetail = () => {
  return (
    <>
      <Navbar />
      <div className=" position-relative">
        <img
          src="/images/jobs-etudiants.jpg"
          // src={img3}
          alt=""
          className="object-fit-cover w-100"
          style={{ height: "25rem" }}
        />
        <h2 className="position-absolute fw-bold start-50 bottom-50 text-light">
          Etudiants
        </h2>
      </div>
      <div className="col-md-7 mx-auto py-5">
        <h3 className="pb-3">
          Le Club de la Capitale offre de belles opportunités aux étudiants en
          quête de nouvelles expériences professionnelles.
        </h3>
        <p className="fs-17">
          Le Paris Saint-Germain recrute de nombreux étudiants en stage ou
          contrat d’alternance.
        </p>
        <p className="fs-17">
          Pour ce faire, il organise chaque année deux Student Mercato pour
          recruter ses futurs talents. Il s’agit d’un Job Dating organisé au
          Parc des Princes, durant lequel notre équipe Ressources Humaines
          rencontre des candidats motivés et créatifs.
        </p>
        <p className="fs-17">
          Le Paris Saint-Germain a développé une politique d’intégration et de
          formation de ses jeunes étudiants et futurs professionnels.
        </p>
        <p className="fs-17">
          Le Club a obtenu en 2019 le label Happy Trainees, remis par la société
          ChooseMyCompany, qui recense les meilleures entreprises où faire un
          stage. En 2019, 88% des stagiaires recommandaient leur expérience au
          sein du Club, en lui accordant l'excellente note de 4,19/5.
        </p>
        <p className="fs-17">
          Merchandising, sponsoring, digital, billetterie, finance... retrouvez
          nos offres de stages et d’alternance.
        </p>
      </div>
    </>
  );
};

export default EtudiantsDetail;
