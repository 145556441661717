import React from "react";
// import insta from "../../images/instagram.png";
// import fb from "../../images/fb.png";

const Presentation = () => {
  return (
    <>
      <div className="col-md-7 mx-auto">
        <h3 className="fs-2 text-blue">
          La Paris Saint-Germain Academy offre une opportunité unique à tous les
          passionnés de sport de partager les valeurs d’un grand club européen,
          reconnu à travers le monde pour son savoir-faire et la qualité de sa
          formation.
        </h3>
        <p>
          Créée en 2005, la Paris Saint-Germain Academy accueille chaque année
          de plus en plus d’enfants au sein de ses nombreuses écoles de
          football, de handball, de judo et d’Esports ; en France et à travers
          le monde.
        </p>
        <p>
          Ouverts aux filles et aux garçons de 4 à 17 ans, ces programmes
          d’entraînement permettent à des milliers d’enfants de bénéficier du
          savoir-faire technique du Paris Saint-Germain avec des cursus à
          l’année ou au travers de stages ponctuels ; pour vivre des expériences
          culturelles et sportives uniques.
        </p>
        <div className="row">
          <div className="col-md-6 py-3">
            <div className="shadow bg-white py-4 px-5 d-flex flex-column rounded justify-content-center align-items-center">
              <h1 className="display-1 text-blue fw-bold">22</h1>
              <h5 className="text-center text-blue fw-bold">PAYS</h5>
            </div>
          </div>
          <div className="col-md-6 py-3">
            <div className="shadow bg-white py-4 px-5 d-flex flex-column rounded justify-content-center align-items-center">
              <h1 className="display-2 text-blue fw-bold">+ 45 000</h1>
              <h5 className="text-center text-blue fw-bold">ENFANTS</h5>
            </div>
          </div>
          <div className="col-md-6 py-3">
            <div className="shadow bg-white py-4 px-5 d-flex flex-column rounded justify-content-center align-items-center">
              <h1 className="display-1 text-blue fw-bold">210</h1>
              <h5 className="text-center text-blue fw-bold">CENTRES</h5>
            </div>
          </div>
          <div className="col-md-6 py-3">
            <div className="shadow bg-white py-4 px-5 d-flex flex-column rounded justify-content-center align-items-center">
              <h1 className="display-1 text-blue fw-bold">+ 700</h1>
              <h5 className="text-center text-blue fw-bold">COACHS</h5>
            </div>
          </div>
        </div>
        <h4 className="text-center pt-4">
          Retrouvez la PSG Academy sur nos réseaux sociaux :
        </h4>
        <div className="d-flex justify-content-center gap-3 pb-4">
          <img src="/images/instagram.png" width={40} alt="" />
          <img src="/images/fb.png" width={40} alt="" />
        </div>
      </div>
    </>
  );
};

export default Presentation;
