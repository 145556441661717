import React from "react";
// import vocation from "../../images/notrevocation.png";
const Vocation = () => {
  return (
    <>
      <div className="container">
        <div className="py-5">
          <h2 className="text-center fw-bold mb-4">Notre mission</h2>
          <div className="row">
            <div className="col-md-6">
              <p className="fs-17">
                Depuis sa création en 2000, la Fondation Paris Saint-Germain
                poursuit l’ambition historique du Club : soutenir et accompagner
                la jeunesse en difficulté. Notre mission consiste à donner aux
                enfants à qui la vie n’offre pas les mêmes chances de départ, le
                coup de pouce nécessaire pour qu’ils se découvrent et
                s’épanouissent. Le Paris Saint-Germain porte sa Fondation depuis
                sa création, en lui permettant de développer des activités pour
                un grand nombre de jeunes aussi bien en France qu’à
                l’international. Chaque année, la Fondation et le Fond de
                Dotation Paris Saint-Germain ont permis d’aider plus de 30 000
                bénéficiaires au travers de 400 opérations.
              </p>
              <p className="fs-17">
                Son Président Nasser Al-Khelaïfi, les joueurs et salariés du
                Club, sont impliqués dans ces projets. Leur temps précieux
                contribue considérablement à la part de rêve et de bonheur
                offerte aux jeunes bénéficiant de nos programmes. Fort de plus
                de 350 éducateurs, formés à la méthodologie et au savoir-faire
                Paris Saint-Germain et animés par la volonté de transmettre par
                l’éducation, nous créons et développons des programmes
                pédagogiques innovants, largement plébiscités par les
                bénéficiaires et nos partenaires.
              </p>
            </div>
            <div className="col-md-6">
              <img src="/images/notrevocation.png" alt="" className="w-100" />
            </div>
          </div>
        </div>
        <div className="py-5">
          <h2 className="text-center fw-bold mb-4">Nos valeurs</h2>
          <div className="row">
            <div className="col-md-6">
              <img src="/images/notrevocation.png" alt="" className="w-100" />
            </div>
            <div className="col-md-6">
              <p className="fs-17">
                La Fondation et le Fonds de dotation Paris Saint-Germain portent
                les valeurs du Club dans la société et avec elles, celles du
                sport : dépassement de soi, solidarité, goût de l’effort,
                entraide, respect ou encore générosité.
              </p>
              <p className="lead" style={{ fontStyle: "italic" }}>
                "La Fondation Paris Saint-Germain - c’est l’expression de
                l’engagement du club en dehors du terrain et en soutien et
                accompagnement d’une jeunesse qui en a le plus besoin. C’est une
                mobilisation de tous les instants des équipes de la Fondation et
                du Fonds de dotation Paris Saint-Germain, ses partenaires et
                tout la communauté PSG auprès de la jeunesse en difficulté, en
                France et dans le monde. En 23 ans, nous avons déjà accompli de
                grandes choses, et ce n’est que le début ! "
              </p>
              <h6 className="fw-bold">
                - NASSER AL-KHELAÏFI,{" "}
                <span className="text-blue">
                  PRÉSIDENT DE LA FONDATION PARIS SAINT GERMAIN ET
                  PRÉSIDENT-DIRECTEUR GÉNÉRAL DU PARIS SAINT-GERMAIN
                </span>
              </h6>
            </div>
          </div>
        </div>
        <div className="col-md-9 mx-auto">
          <h3 className="text-center fw-bold">
            Au coeur de nos axes d'engagement : l'envie d'aider à construire
            l'avenir
          </h3>
          <div className="pt-5">
            <h6 className="fw-bold">EDUCATION</h6>
            <p className="fs-17">
              La Fondation Paris Saint-Germain place la jeunesse en difficulté
              au cœur de son projet. A travers ses programmes innovants, elle
              s’appuie sur les vertus et valeurs éducatives pour offrir de
              nouvelles perspectives à ses bénéficiaires.
            </p>
          </div>
          <div className="pt-5">
            <h6 className="fw-bold">EMPOWERMENT</h6>
            <p className="fs-17">
              La Fondation Saint-Germain croit fortement que le meilleur moyen
              de soutenir les jeunes qui en ont le plus besoin pour faire face
              aux difficultés est de les aider à prendre confiance en eux, se
              sentir légitime et s’épanouir.
            </p>
          </div>
          <div className="pt-5">
            <h6 className="fw-bold">TRANSMISSION</h6>
            <p className="fs-17">
              Que ce soit lors d’intervention d’athlète de l’équipe ou par
              l’animation des programmes par ses éducateurs, la Fondation
              Saint-Germain fait de la transmission un principe d’action
              fondamental de l’accompagnement des jeunes qui en ont le plus
              besoin.
            </p>
          </div>
          <h3 className="text-center fw-bold">Nos actions en chiffres</h3>
          <div className="row">
            <div className="col-md-6 py-3">
              <div className="shadow bg-white py-4 px-5 d-flex flex-column rounded justify-content-center align-items-center">
                <h1 className="display-1 text-blue fw-bold">400</h1>
                <p className="text-center text-blue">
                  OPÉRA TIONS CHAQUE ANNÉE
                </p>
              </div>
            </div>
            <div className="col-md-6 py-3">
              <div className="shadow bg-white py-4 px-5 d-flex flex-column rounded justify-content-center align-items-center">
                <h1 className="display-1 text-blue fw-bold">300 000</h1>
                <p className="text-center text-blue">
                  ENFANTS ET JEUNES ACCOMPAGNÉS DEPUIS LA CRÉATION DE LA
                  FONDATION EN 2000 ET 30 000 SUR LA SAISON 22-23
                </p>
              </div>
            </div>
            <div className="col-md-6 py-3">
              <div className="shadow bg-white py-4 px-5 d-flex flex-column rounded justify-content-center align-items-center">
                <h1 className="display-1 text-blue fw-bold">1 500</h1>
                <p className="text-center text-blue">
                  ÉTUDIANTS SOUTENUS CHAQUE SAISON, GRÂCE À NOTRE PROGRAMME DE
                  SOUTIEN ALIMENTAIRE
                </p>
              </div>
            </div>
            <div className="col-md-6 py-3">
              <div className="shadow bg-white py-4 px-5 d-flex flex-column rounded justify-content-center align-items-center">
                <h1 className="display-1 text-blue fw-bold">1 500</h1>
                <p className="text-center text-blue">
                  RÊVES D'ENFANTS MALADES RÉALISÉS CHAQUE SAISON
                </p>
              </div>
            </div>
            <div className="col-md-6 py-3">
              <div className="shadow bg-white py-4 px-5 d-flex flex-column rounded justify-content-center align-items-center">
                <h1 className="display-1 text-blue fw-bold">+100</h1>
                <p className="text-center text-blue">
                  BÉNÉVOLES SALARIÉS DU CLUB, ENGAGÉS CHAQUE SAISON
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vocation;
