import React from "react";
import infinite from "../images/infinite-athlete.webp";
import nike from "../images/nike.webp";
import bingx from "../images/bingx.webp";
import trivago from "../images/trivago.webp";

const Footer = () => {
  return (
    <div>
      {/* -------------- */}
      <div className="d-flex justify-content-center gap-4 bg-white py-3">
        <img src="/images/infinite-athlete.png" width={150} alt="" />
        <img src="/images/nike.png" width={150} alt="" />
        <img src="/images/bingx.png" width={150} alt="" />
        <img src="/images/trivago.png" width={150} alt="" />
      </div>
    </div>
  );
};

export default Footer;
