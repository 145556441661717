import React from "react";
// import banner from "../../images/banner.webp";

const HeroSection = () => {
  return (
    <>
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div
              className="mainBanner"
              style={{
                background:
                  'linear-gradient(rgba(28, 38, 78, 0.5), rgba(28, 38, 78, 0.5)), url("/images/hero-first-team-2023.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center h-100">
              <h1 className="display-2 fw-bold">Lorem ipsum dolor sit .</h1>
              <p className="fs-4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius
                quod facilis sed! Eum commodi voluptates obcaecati minima odio
                temporibus dignissimos?{" "}
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <div
              className="mainBanner2"
              style={{
                background:
                  'linear-gradient(rgba(28, 38, 78, 0.5), rgba(28, 38, 78, 0.5)), url("/images/banner.png")',
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center h-100">
              <h1 className="display-2 fw-bold">Lorem ipsum dolor sit .</h1>
              <p className="fs-4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius
                quod facilis sed! Eum commodi voluptates obcaecati minima odio
                temporibus dignissimos?{" "}
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <div
              className="mainBanner3"
              style={{
                background:
                  'linear-gradient(rgba(28, 38, 78, 0.5), rgba(28, 38, 78, 0.5)), url("/images/_51a5217.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center h-100">
              <h1 className="display-2 fw-bold">Lorem ipsum dolor sit .</h1>
              <p className="fs-4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius
                quod facilis sed! Eum commodi voluptates obcaecati minima odio
                temporibus dignissimos?{" "}
              </p>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <div
            className="bg-blue d-flex justify-content-center align-items-center"
            style={{ padding: "1rem" }}
          >
            <span
              className="carousel-control-prev-icon "
              aria-hidden="true"
            ></span>
          </div>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <div
            className="bg-blue d-flex justify-content-center align-items-center"
            style={{ padding: "1rem" }}
          >
            <span
              className="carousel-control-next-icon "
              aria-hidden="true"
            ></span>
          </div>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};

export default HeroSection;
