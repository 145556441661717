import React from "react";
import Navbar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import videoData from "./VideoData";

const VideoDetail = () => {
  const { id } = useParams();
  const cardVideo = videoData.find((val) => val.id === id);

  return (
    <>
      <Navbar />
      <div className="mx-4 py-5">
        <div className="col-md-12">
          <video className="w-100" controls autoplay loop muted>
            <source src="/images/video-banner.mp4" type="video/mp4" />
            {/* <source src={cardVideo.video} type="video/mp4" /> */}
            Your browser does not support the video tag.
          </video>
          <div className="d-flex gap-2">
            <p className="fw-bold ">{cardVideo.category}</p>
            <p className="">|</p>
            <p className="">{cardVideo.date}</p>
          </div>
          <h4 className="fw-bold">{cardVideo.title}</h4>
          <h5 className="">{cardVideo.para}</h5>
        </div>
      </div>
    </>
  );
};

export default VideoDetail;
