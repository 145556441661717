// import React from "react";
// import Navbar from "../components/Navbar";

// const Login = () => {
//   return (
//     <>
//       <Navbar />
//       <div>

//       </div>
//     </>
//   );
// };

// export default Login;
import React from "react";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";

const Login = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <>
      <Navbar />
      <div className="py-5">
        <div className="container_form container">
          <form onSubmit={handleSubmit}>
            <div className="title">Login</div>
            <div className="input-box underline">
              <input type="text" placeholder="Enter Your Email" required />
              <div className="underline"></div>
            </div>
            <div className="input-box">
              <input
                type="password"
                placeholder="Enter Your Password"
                required
              />
              <div className="underline"></div>
            </div>
            <div className="input-box button">
              <input type="submit" value="Continue" />
            </div>
          </form>

          {/* <div className="option">or Connect With Social Media</div> */}
          <div className="row align-items-center justify-content-center mx-1">
            <div
              className="col-md-5"
              style={{ height: "2px", backgroundColor: "#ccc" }}
            ></div>
            <div className="col-md-2 d-flex justify-content-center">or</div>
            <div
              className="col-md-5"
              style={{ height: "2px", backgroundColor: "#ccc" }}
            ></div>
          </div>
          <div className="twitter">
            <a href="#">
              <FcGoogle className=" fs-3" />
              Sign in With Google
            </a>
          </div>
          <div className="facebook text-light">
            <a href="#">
              <FaFacebook className="fs-4" />
              Sign in With Facebook
            </a>
          </div>
          <p className="text-center">
            Don't have an account?{" "}
            <Link to={"/signup"} className="text-blue fw-bold ">
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
