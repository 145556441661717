import React, { useState } from "react";
import videoData from "./VideoData";
import Navbar from "../../components/Navbar";
import { FaPlay } from "react-icons/fa";
import { Link } from "react-router-dom";

const OFKtv = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = videoData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Navbar />
      <div className="mx-4 py-5">
        <p className="fw-bold fs-17">TOUTES LES VIDÉOS</p>
        <div className="row">
          {currentItems.map((val, index) => (
            <div className="col-md-3 mb-3" key={index}>
              <Link to={`/videoDetail/${val.id}`}>
                <div className="shadow h-100">
                  <div style={{ position: "relative" }}>
                    <div className="image-container">
                      <video className="w-100 zoom-img" poster={val.img}>
                        <source src={val.video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <div
                        style={{
                          position: "absolute",
                          left: "5px",
                          bottom: "12px",
                        }}
                        className="bg-blue py-2 px-3"
                      >
                        <FaPlay className="text-light" />
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="d-flex gap-2">
                      <p className="fw-bold mb-0">{val.category}</p>
                      <p className="mb-0">|</p>
                      <p className="mb-0">{val.date}</p>
                    </div>
                    <p className="fs-17 fw-bold">{val.title}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center pb-5">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => paginate(currentPage - 1)}
            >
              Previous
            </button>
          </li>
          {Array.from(
            { length: Math.ceil(videoData.length / itemsPerPage) },
            (_, index) => (
              <li
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                key={index}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            )
          )}
          <li
            className={`page-item ${
              currentPage === Math.ceil(videoData.length / itemsPerPage)
                ? "disabled"
                : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => paginate(currentPage + 1)}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default OFKtv;
