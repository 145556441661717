import React from "react";
// import img1 from "../../../images/nike.webp";
// import img2 from "../../../images/qatar-airways.png";
// import img3 from "../../../images/goat.png";
// import img4 from "../../../images/visit-qatar-parts.png";
// import img5 from "../../../images/all-23.png";
// import img6 from "../../../images/ea-23.png";
// import img7 from "../../../images/aspetar.png";
// import img8 from "../../../images/parions-sport-23.png";
// import img9 from "../../../images/yassir.png";
// import img10 from "../../../images/cocacola.png";
// import img11 from "../../../images/mcdonalds.png";
// import img12 from "../../../images/hisense.png";
// import img13 from "../../../images/socios.png";
// import img14 from "../../../images/replay.png";
// import img15 from "../../../images/theragun.png";
// import img16 from "../../../images/dior-dark.png";
// import img17 from "../../../images/nivea-men.png";
// import img18 from "../../../images/logo-purnell-part.png";
// import img19 from "../../../images/parisbaguette.png";
// import img20 from "../../../images/americanexpress.png";
// import img24 from "../../../images/soklin.png";
// import img22 from "../../../images/aje-logo.png";
// import img23 from "../../../images/amuse.png";
// import img21 from "../../../images/sportswater.png";
// import img25 from "../../../images/emma.png";

const EquipePremière = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/nike.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/qatar-airways.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/goat.png" width={120} alt="" />
            </a>
          </div>
        </div>
        <div className="p fs-17 fw-bold mt-5">PARTENAIRES PREMIUM</div>
        <div className="row">
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/visit-qatar-parts.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/all-23.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/ea-23.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/aspetar.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/parions-sport-23.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/yassir.png" width={120} alt="" />
            </a>
          </div>
        </div>
        <p className="fw-bold mt-5">PARTENAIRES OFFICIELS</p>
        <div className="row">
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/cocacola.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/mcdonalds.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/hisense.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/socios.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/replay.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/theragun.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/dior-dark.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/nivea-men.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/logo-purnell-part.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/parisbaguette.png" width={120} alt="" />
            </a>
          </div>
        </div>
        <p className="mt-5 fw-bold fs-17">FOURNISSEURS OFFICIELS</p>
        <div className="row">
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/americanexpress.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/sportswater.png" width={120} alt="" />
            </a>
          </div>
        </div>
        <p className="mt-5 fw-bold fs-17">PARTENAIRES RÉGIONAUX</p>
        <div className="row">
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/aje-logo.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/amuse.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/soklin.png" width={120} alt="" />
            </a>
          </div>
        </div>
        <p className="mt-5 fw-bold fs-17">PARTENAIRES CAMPUS</p>
        <div className="col-md-3 py-2">
          <a
            href="##"
            className="bg-light d-flex justify-content-center align-items-center"
            style={{ height: "13rem" }}
          >
            <img src="/images/emma.png" width={120} alt="" />
          </a>
        </div>
      </div>
    </>
  );
};

export default EquipePremière;
