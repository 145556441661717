import React, { useState } from "react";
import Navbar from "../../../components/Navbar";
import EquipePremière from "./EquipePremière";
import Handball from "./Handball";
import Feminines from "./Feminines";
import Esports from "./Esports";

const Partenaires = () => {
  const [activeButton, setActiveButton] = useState("equipe-premiere");
  const [showStaff, setShowStaff] = useState(true);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setShowStaff(buttonName === "equipe-premiere");
  };

  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center gap-3 py-5">
        {" "}
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "equipe-premiere" ? "active" : ""
          }`}
          style={{ border: "1px solid #ccc" }}
          onClick={() => handleButtonClick("equipe-premiere")}
        >
          Equipe Première
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "handball" ? "active" : ""
          }`}
          style={{ border: "1px solid #ccc" }}
          onClick={() => handleButtonClick("handball")}
        >
          Handball
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "feminines" ? "active" : ""
          }`}
          style={{ border: "1px solid #ccc" }}
          onClick={() => handleButtonClick("feminines")}
        >
          Feminines
        </button>
        <button
          className={`active_btn btn py-2 px-3 ${
            activeButton === "esports" ? "active" : ""
          }`}
          style={{ border: "1px solid #ccc" }}
          onClick={() => handleButtonClick("esports")}
        >
          Esports
        </button>
      </div>
      {showStaff && <EquipePremière />}
      {activeButton === "handball" && <Handball />}
      {activeButton === "feminines" && <Feminines />}
      {activeButton === "esports" && <Esports />}
    </>
  );
};

export default Partenaires;
