import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import data from "./MatchesData";

const CalendrierEt = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const filteredData = data.filter((item) => {
    const categoryMatch =
      !selectedCategory || item.category === selectedCategory;
    const dateMatch = !selectedDate || item.date.includes(selectedDate);

    return categoryMatch && dateMatch;
  });

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center py-5">
        <select className="py-3 px-2" onChange={handleCategoryChange}>
          <option value="">Toutes les compétitions</option>
          <option value="LIGUE 1">LIGUE 1</option>
          <option value="COUPE DE FRANCE">COUPE DE FRANCE</option>
          <option value="COUPE DE LA LIGUE">COUPE DE LA LIGUE</option>
          <option value="UEFA Champions League">UEFA Champions League</option>
          <option value="TROPHÉE DES CHAMPIONS">TROPHÉE DES CHAMPIONS</option>
          <option value="AMICAL">AMICAL</option>
        </select>

        <select className="py-3 px-2" onChange={handleDateChange}>
          <option value="2023">2023/2024</option>
          <option value="2014">2014/2015</option>
          <option value="2015">2015/2016</option>
          <option value="2016">2016/2017</option>
          <option value="2017">2017/2018</option>
          <option value="2018">2018/2019</option>
          <option value="2019">2019/2020</option>
          <option value="2020">2020/2021</option>
          <option value="2021">2021/2022</option>
          <option value="2022">2022/2023</option>
          <option value="2023">2023/2024</option>
        </select>
      </div>
      <div className="mx-5">
        <div className="py-4 d-flex justify-content-between">
          <h4>JUILLET 2023</h4>
          <p>* Dates et heures affichées dans votre fuseau horaire</p>
        </div>
        {filteredData.length > 0 ? (
          filteredData.map((val, index) => (
            <div key={index} className="col-md-12 pb-3">
              <div className="bg-white p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="fw-bold">{val.category}</p>
                  <div>
                    <h6 className="text-center">{val.date}</h6>
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <h4 className="mb-0">{val.team1}</h4>
                      <img src={val.logo1} width={60} alt="" />
                      <h5 className="p-3 bg-secondary text-light rounded">
                        {val.point1}
                      </h5>
                      <h5 className="p-3 bg-secondary text-light rounded">
                        {val.point2}
                      </h5>
                      <img src={val.logo2} width={60} alt="" />
                      <h4 className="mb-0">{val.team2}</h4>
                    </div>
                    <p className="text-secondary text-center fs-12 mb-1 mt-2 mb-0">
                      {val.location}
                    </p>
                  </div>
                  <button className="btn bg-blue rounded-0 text-light ">
                    Compte-Rendu
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No matches found for the selected category and date.</p>
        )}
      </div>
    </>
  );
};

export default CalendrierEt;
