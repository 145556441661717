import React from "react";

const Staff = () => {
  return (
    <>
      <div className="mx-5 py-5">
        <h4>Staff technique</h4>
        <div className="row">
          <div className="col-md-4 mb-3">
            <div
              class="staff_cardImg"
              style={{
                backgroundImage:
                  'linear-gradient(rgba(0, 0, 0, 0.1), rgba(32, 31, 31, 0.9)), url("/images/cards-img.png")',
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <h6 className="text-light">ENTRAÎNEUR</h6>
              <h1 className="text-light">Luis Enrique</h1>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div
              class="staff_cardImg"
              style={{
                backgroundImage:
                  'linear-gradient(rgba(0, 0, 0, 0.1), rgba(32, 31, 31, 0.9)), url("/images/cards-img.png")',
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <h6 className="text-light">ENTRAÎNEUR</h6>
              <h1 className="text-light">Luis Enrique</h1>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div
              class="staff_cardImg"
              style={{
                backgroundImage:
                  'linear-gradient(rgba(0, 0, 0, 0.1), rgba(32, 31, 31, 0.9)), url("/images/cards-img.png")',
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <h6 className="text-light">ENTRAÎNEUR</h6>
              <h1 className="text-light">Luis Enrique</h1>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div
              class="staff_cardImg"
              style={{
                backgroundImage:
                  'linear-gradient(rgba(0, 0, 0, 0.1), rgba(32, 31, 31, 0.9)), url("/images/cards-img.png")',
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <h6 className="text-light">ENTRAÎNEUR</h6>
              <h1 className="text-light">Luis Enrique</h1>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div
              class="staff_cardImg"
              style={{
                backgroundImage:
                  'linear-gradient(rgba(0, 0, 0, 0.1), rgba(32, 31, 31, 0.9)), url("/images/cards-img.png")',
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <h6 className="text-light">ENTRAÎNEUR</h6>
              <h1 className="text-light">Luis Enrique</h1>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div
              class="staff_cardImg"
              style={{
                backgroundImage:
                  'linear-gradient(rgba(0, 0, 0, 0.1), rgba(32, 31, 31, 0.9)), url("/images/cards-img.png")',
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <h6 className="text-light">ENTRAÎNEUR</h6>
              <h1 className="text-light">Luis Enrique</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Staff;
