import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";

// import banner1 from "../../images/shirtsBanner.jpg";
// import banner2 from "../../images/shirtbanner3.jpeg";
// import banner3 from "../../images/shirtbanner2.jpeg";
// import shop1 from "../../images/shop1.jpg";
// import shop2 from "../../images/shop2.jpg";
// import shop3 from "../../images/shop3.webp";
// import shop4 from "../../images/shop4.jpg";
// import shop5 from "../../images/shop5.jpg";
// import shop6 from "../../images/shop6.jpg";
// import pid1 from "../../images/PID_1.jpg";
// import pid2 from "../../images/PID_2.jpg";
// import pid3 from "../../images/PID_3.jpg";
// import pid4 from "../../images/PID_4.jpg";
// import pid5 from "../../images/PID_5.jpg";
// import pid6 from "../../images/PID_6.jpg";
// import img1 from "../../images/DT_B_Spot_Left_FR.jpg";
// import img2 from "../../images/bspot_left_biglogo_fr.jpg";
// import img3 from "../../images/bspot_left_icp_fr.jpg";
// import img4 from "../../images/bspot_right_essentials_fr.jpg";
import Navbar from "../../components/Navbar";


const Boutique = () => {
  return (
    <div className="bg-white">
      <Navbar />
      <img src="/images/shirtsBanner.jpg" className="w-100" alt="" />
      <div className="mx-4">
        <h5 className="fw-bold">COMMANDEZ NOS MAILLOTS</h5>
        <div className="row">
          <div className="col-md-3">
            <img src="/images/shop1.jpg" className="w-100" alt="" />
          </div>
          <div className="col-md-3">
            <img src="/images/shop2.jpg" className="w-100" alt="" />
          </div>
          <div className="col-md-3">
            <img src="/images/shop3.jpg" className="w-100" alt="" />
          </div>
          <div className="col-md-3">
            <img src="/images/shop4.jpg" className="w-100" alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src="/images/shop5.jpg" className="w-100" alt="" />
          </div>
          <div className="col-md-6">
            <img src="/images/shop6.jpg" className="w-100" alt="" />
          </div>
        </div>
        <img src="/images/shirtbanner3.jpeg" className="w-100" alt="" />
        <h4 className="fw-bold text-center mb-3 mt-5">ACHETER PAR JOUEUR</h4>
        <div className="row">
          <div className="col-md-2">
            <img src="/images/PID_1.jpg" className="w-100" alt="" />
          </div>
          <div className="col-md-2">
            <img src="/images/PID_2.jpg" className="w-100" alt="" />
          </div>
          <div className="col-md-2">
            <img src="/images/PID_3.jpg" className="w-100" alt="" />
          </div>
          <div className="col-md-2">
            <img src="/images/PID_4.jpg" className="w-100" alt="" />
          </div>
          <div className="col-md-2">
            <img src="/images/PID_5.jpg" className="w-100" alt="" />
          </div>
          <div className="col-md-2">
            <img src="/images/PID_6.jpg" className="w-100" alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src="/images/DT_B_Spot_Left_FR.jpg" className="w-100" alt="" />
          </div>
          <div className="col-md-6">
            <img src="/images/bspot_left_biglogo_fr.jpg" className="w-100" alt="" />
          </div>
        </div>
        <img src="/images/shirtbanner2.jpeg" className="w-100" alt="" />
        <Swiper
          slidesPerView={5}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src="/images/PID_1.jpg" alt="" />
            <p>US 190</p>
            <small>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
              ipsum.
            </small>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/PID_1.jpg" alt="" />
            <p>US 190</p>
            <small>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
              ipsum.
            </small>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/PID_1.jpg" alt="" />
            <p>US 190</p>
            <small>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
              ipsum.
            </small>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/PID_1.jpg" alt="" />
            <p>US 190</p>
            <small>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
              ipsum.
            </small>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/PID_1.jpg" alt="" />
            <p>US 190</p>
            <small>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
              ipsum.
            </small>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/PID_1.jpg" alt="" />
            <p>US 190</p>
            <small>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
              ipsum.
            </small>
          </SwiperSlide>
        </Swiper>
        <div className="row">
          <div className="col-md-6">
            <img src="/images/bspot_left_icp_fr.jpg" className="w-100" alt="" />
          </div>
          <div className="col-md-6">
            <img src="/images/bspot_right_essentials_fr.jpg" className="w-100" alt="" />
          </div>
        </div>
        <Swiper
          slidesPerView={5}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src="/images/PID_1.jpg" alt="" />
            <p>US 190</p>
            <small>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
              ipsum.
            </small>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/PID_1.jpg" alt="" />
            <p>US 190</p>
            <small>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
              ipsum.
            </small>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/PID_1.jpg" alt="" />
            <p>US 190</p>
            <small>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
              ipsum.
            </small>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/PID_1.jpg" alt="" />
            <p>US 190</p>
            <small>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
              ipsum.
            </small>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/PID_1.jpg" alt="" />
            <p>US 190</p>
            <small>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
              ipsum.
            </small>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/PID_1.jpg" alt="" />
            <p>US 190</p>
            <small>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
              ipsum.
            </small>
          </SwiperSlide>
        </Swiper>
        <div className="py-5 mx-5">
          <div style={{ height: "7rem", overflowY: "scroll" }}>
            <p>
              La boutique officielle en ligne du Paris Saint-Germain référence
              tous les articles officiels développés pour les fans du Club
              parisien. Que vous recherchiez des tenues de match Paris
              Saint-Germain authentiques pour toute la famille ou des souvenirs
              pour votre collection de fan, vous trouverez exactement ce que
              vous cherchez ici !
            </p>
            <p>
              Achetez vos maillots 2023/24 Paris Saint-Germain, ainsi que les
              tenues third, fourth et gardien pour les hommes, les femmes et les
              enfants. Soutenez vos joueurs préférés avec les maillots Dembélé y
              Kylian Mbappé, ou personnalisez un maillot avec votre propre nom
              et numéro pour votre prochaine sortie au stade. Entraînez-vous
              comme les pros avec l'équipement d'entraînement Nike, des
              chaussures et tous nos équipements de foot ! Pendant que vous êtes
              ici, n'oubliez pas de vous procurer quelques accessoires officiels
              du Paris Saint-Germain pour votre maison ou votre bureau,
              disponibles exclusivement dans la boutique en ligne du PSG.
            </p>
            <p>
              L'équipe du service client de la boutique officielle en ligne du
              Paris Saint-Germain se consacre à vous offrir, en tant que fan,
              une expérience par excellence. Pour obtenir des réponses aux
              questions fréquemment posées ou pour bénéficier du service client,
              consulter notre menu facile à utiliser pour des achats sans souci.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boutique;
