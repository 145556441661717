import React from "react";
// import img from "../../images/foundation-header-image.jpg";

const Contacter = () => {
  return (
    <>
      <div className="col-md-8 mx-auto">
        <h4 className="text-center">
          Vous pouvez contacter la Fondation Paris Saint-Germain via le
          formulaire ci-dessous :
        </h4>
        <img
          src="/images/foundation-header-image.jpg"
          className="w-100 object-fit-cover"
          height={300}
          alt=""
        />
      </div>

      <div className="col-md-6 mx-auto py-5">
        <h1 className=" fw-bold">
          Formulaire destiné aux structures souhaitant devenir bénéficiaire de
          la Fondation PSG
        </h1>
        <p className="fs-17 fw-bold">
          Merci de l’intérêt que vous portez à la Fondation PSG. Afin d’étudier
          votre demande, nous avons besoin d’en savoir plus sur votre structure
          et vos actions 😀
        </p>
        <button className="btn text-light bg-blue rounded-0">D'accord</button>
      </div>
    </>
  );
};

export default Contacter;
