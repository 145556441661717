import React from "react";
// import img1 from "../../../images/nike.webp";
// import img2 from "../../../images/qatar-airways.png";
// import img3 from "../../../images/visit-rwanda.png";
// import img4 from "../../../images/deloitte.png";
// import img5 from "../../../images/orange.svg";
// import img6 from "../../../images/amos.png";
// import img7 from "../../../images/ooredoo.svg";
// import img8 from "../../../images/qnb.svg";
// import img9 from "../../../images/visit-qatar-parts.png";
// import img10 from "../../../images/ea-23.png";
// import img11 from "../../../images/yassir.png";
// import img12 from "../../../images/parisbaguette.png";
// import img13 from "../../../images/cocacola.png";
// import img14 from "../../../images/theragun.png";

const Feminines = () => {
  return (
    <>
      <div className="container">
        <p className="fw-bold fs-17">PARTENAIRES PRINCIPAUX</p>
        <div className="row">
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/nike.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/qatar-airways.png" width={120} alt="" />
            </a>
          </div>
        </div>
        <p className="fw-bold fs-17">PARTENAIRES OFFICIELS</p>

        <div className="row">
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/visit-rwanda.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/deloitte.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/orange.svg" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/amos.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/ooredoo.svg" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/qnb.svg" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/visit-qatar-parts.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/ea-23.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/yassir.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/parisbaguette.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/cocacola.png" width={120} alt="" />
            </a>
          </div>
          <div className="col-md-3 py-2">
            <a
              href="##"
              className="bg-light d-flex justify-content-center align-items-center"
              style={{ height: "13rem" }}
            >
              <img src="/images/theragun.png" width={120} alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feminines;
