// import img from "../../images/psgtv-friendlies-23b.jpeg";
// import img1 from "../../images/mbappematch.jpg";
// import img2 from "../../images/C";
// import img3 from "../../images/this-is-paris-22-23.jpg";

const ofkData = [
  {
    id: "1",
    img: "/images/mbappematch.jpg",
    // img: img,
    title: "Tous les matchs de pré-saison en live",
    para: " Regardez tous les matches amicaux de pré-saison de l’équipe première en direct vidéo sur PSG.FR et dans l’application PSG Officiel.",
  },
  {
    id: "2",
    img: "/images/kanginmatch.jpg",
    // img: img1,
    title: "Tous les matches en replay",
    para: " Revivez tous les matches du Paris Saint-Germain en intégralité et en résumé long dès minuit le soir du match (UEFA Champions League et Coupe de France) ou dès minuit le soir du dernier match de la journée (Ligue 1).",
  },
  {
    id: "3",
    img: "/images/kanginmatch.jpg",
    // img: img2,
    title: "Un long format pour chaque match",
    para: " Vous n'avez pas le temps de visionner ou re-visionner le match en entier ? Les résumés de 10 minutes vous proposent les meilleures actions du match et leurs ralentis.",
  },
  {
    id: "4",
    img: "/images/this-is-paris-22-23.jpg",
    // img: img3,
    title: "Au coeur du club avec This is Paris",
    para: " Chaque semaine, vivez 26 minutes en immersion au coeur du Paris Saint-Germain. Grâce à This Is Paris, profitez d'images inédites et d'entretiens exclusifs, suivez la préparation de l’Equipe Première au Centre Ooredoo ou lors des déplacements, accompagnez les Féminines dans leur quête de trophées et assistez à l’évolution des jeunes du Centre de Formation.",
  },
];
export default ofkData;
