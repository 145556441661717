// import img from "../../images/banner.webp";
// import slide1 from "../../images/slide1.jpg";
// import slide2 from "../../images/slide2.jpg";
// import slide3 from "../../images/slide3.jpg";
// import slide4 from "../../images/slide4.jpg";
// import slide5 from "../../images/slide5.jpg";
// import slide6 from "../../images/slide6.jpg";

const photosData = [
  {
    id: "1",
    img: "/images/banner.png",
    // img: img,
    category: "FOOTBALL FEMININ",
    date: "23 mars 2024",
    title: "La France U23 de Barcola s'impose face à la Côte d'Ivoire",
    para: "Revivez la rencontre entre les équipes féminines du Paris Saint-Germain et du BK Häcken (3-0), ce jeudi 28 mars au Parc des Princes à l'occasion des quarts de finale retour de l'UEFA Women's Champions League, à travers notre sélection de photos.",
    img1: "/images/slide1.jpg",
    img2: "/images/slide2.jpg",
    img3: "/images/slide3.jpg",
    img4: "/images/slide4.jpg",
    img5: "/images/slide5.jpg",
    img6: "/images/slide6.jpg",
  },
  {
    id: "2",
    img: "/images/banner.png",
    category: "HANDBALL",
    date: "23 mars 2024",
    title: "La France U23 de Barcola s'impose face à la Côte d'Ivoire",
    para: "Revivez la rencontre entre les équipes féminines du Paris Saint-Germain et du BK Häcken (3-0), ce jeudi 28 mars au Parc des Princes à l'occasion des quarts de finale retour de l'UEFA Women's Champions League, à travers notre sélection de photos.",
    img1: "/images/slide1.jpg",
    img2: "/images/slide2.jpg",
    img3: "/images/slide3.jpg",
    img4: "/images/slide4.jpg",
    img5: "/images/slide5.jpg",
    img6: "/images/slide6.jpg",
  },
  {
    id: "3",
    img: "/images/banner.png",
    category: "CLUB",
    date: "23 mars 2024",
    title: "La France U23 de Barcola s'impose face à la Côte d'Ivoire",
    para: "Revivez la rencontre entre les équipes féminines du Paris Saint-Germain et du BK Häcken (3-0), ce jeudi 28 mars au Parc des Princes à l'occasion des quarts de finale retour de l'UEFA Women's Champions League, à travers notre sélection de photos.",
    img1: "/images/slide1.jpg",
    img2: "/images/slide2.jpg",
    img3: "/images/slide3.jpg",
    img4: "/images/slide4.jpg",
    img5: "/images/slide5.jpg",
    img6: "/images/slide6.jpg",
  },
  {
    id: "4",
    img: "/images/banner.png",
    category: "EQUIPE PREMIÈRE",
    date: "23 mars 2024",
    title: "La France U23 de Barcola s'impose face à la Côte d'Ivoire",
    para: "Revivez la rencontre entre les équipes féminines du Paris Saint-Germain et du BK Häcken (3-0), ce jeudi 28 mars au Parc des Princes à l'occasion des quarts de finale retour de l'UEFA Women's Champions League, à travers notre sélection de photos.",
    img1: "/images/slide1.jpg",
    img2: "/images/slide2.jpg",
    img3: "/images/slide3.jpg",
    img4: "/images/slide4.jpg",
    img5: "/images/slide5.jpg",
    img6: "/images/slide6.jpg",
  },
  {
    id: "5",
    img: "/images/banner.png",
    category: "FOOTBALL FEMININ",
    date: "23 mars 2024",
    title: "La France U23 de Barcola s'impose face à la Côte d'Ivoire",
    para: "Revivez la rencontre entre les équipes féminines du Paris Saint-Germain et du BK Häcken (3-0), ce jeudi 28 mars au Parc des Princes à l'occasion des quarts de finale retour de l'UEFA Women's Champions League, à travers notre sélection de photos.",
    img1: "/images/slide1.jpg",
    img2: "/images/slide2.jpg",
    img3: "/images/slide3.jpg",
    img4: "/images/slide4.jpg",
    img5: "/images/slide5.jpg",
    img6: "/images/slide6.jpg",
  },
];
export default photosData;
