import React from "react";
import Navbar from "../../components/Navbar";
// import img1 from "../../images/psg-logo.png";
// import img2 from "../../images/monaco.png";
// import img3 from "../../images/brest.png";
// import img4 from "../../images/losc.png";

const Classement = () => {
  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center py-5">
        <select className="py-3 px-2">
          <option value=""> 2023/2024</option>
          <option value=""> 2023/2024</option>
          <option value=""> 2023/2024</option>
          <option value=""> 2023/2024</option>
          <option value=""> 2023/2024</option>
          <option value=""> 2023/2024</option>
        </select>
        <select className="py-3 px-2">
          <option value=""> Ligue 1</option>
          <option value="">UEFA Champions League</option>
          <option value="">Trophée des Champions</option>
          <option value="">Amical</option>
        </select>
      </div>
      {/* ----- */}
      <div className="container py-5">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">POS</th>
              <th scope="col">ÉQUIPE</th>
              <th scope="col">PTS</th>
              <th scope="col">J</th>
              <th scope="col">G</th>
              <th scope="col">N</th>
              <th scope="col">P</th>
              <th scope="col">BP</th>
              <th scope="col">BC</th>
              <th scope="col">DIFF.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>
                <img src="/images/psg-logo.png" width={30} alt="" />
                PARIS SAINT-GERMAIN
              </td>
              <td>49</td>
              <td>27</td>
              <td>14</td>
              <td>7</td>
              <td>6</td>
              <td>52</td>
              <td>38</td>
              <td>+14</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>
                <img src="/images/monaco.png" width={30} alt="" />
                AS MONACO
              </td>
              <td>47</td>
              <td>26</td>
              <td>13</td>
              <td>8</td>
              <td>5</td>
              <td>36</td>
              <td>20</td>
              <td>+16</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>
                <img src="/images/brest.png" width={30} alt="" />
                STADE BRESTOIS 29
              </td>
              <td>46</td>
              <td>27</td>
              <td>12</td>
              <td>10</td>
              <td>5</td>
              <td>39</td>
              <td>24</td>
              <td>+15</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>
                <img src="/images/losc.png" width={30} alt="" />
                LOSC LILLE
              </td>
              <td>43</td>
              <td>26</td>
              <td>12</td>
              <td>7</td>
              <td>7</td>
              <td>27</td>
              <td>20</td>
              <td>+7</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Classement;
