import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";

import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import Actualite from "./pages/EquipePremiere/Actualite";
import EffectifMain from "./pages/EquipePremiere/EffectifMain";
import CalendrierEt from "./pages/EquipePremiere/CalendrierEt";
import Classement from "./pages/EquipePremiere/Classement";
import Palmares from "./pages/EquipePremiere/Palmares";
import Photos from "./pages/medias/Photos";
import Podcasts from "./pages/medias/Podcasts";
import ArticleDetail from "./pages/EquipePremiere/ArticleDetail";
import photosDetail from "./pages/medias/PhotosDetail";
import Fondation from "./pages/club/Fondation";
import OFKtv from "./pages/medias/OFKtv";
import VideoDetail from "./pages/medias/VideoDetail";
import AcademieOFK from "./pages/medias/AcademieOFK";
import Academy from "./pages/club/Academy";
import ClubTour from "./pages/club/ClubTour";
import Carrières from "./pages/leClub/carrieres/Carrières";
// import CarrièresDetail from "./pages/leClub/CarrièresDetail";
import PhilosophieDetail from "./pages/leClub/carrieres/PhilosophieDetail";
import CoeurDetail from "./pages/leClub/carrieres/CoeurDetail";
import EtudiantsDetail from "./pages/leClub/carrieres/EtudiantsDetail";
import NotreFamille from "./pages/leClub/carrieres/NotreFamille";
import NousRejoindre from "./pages/leClub/carrieres/NousRejoindre";
import Partenaires from "./pages/leClub/partenaires/Partenaires";
import Présidence from "./pages/leClub/Présidence";
import Boutique from "./pages/shop/Boutique";
import MaillotsPortés from "./pages/shop/MaillotsPortés";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/login" Component={Login} />
        <Route path="/signup" Component={Signup} />
        <Route path="/actualite" Component={Actualite} />
        <Route path="/articleDetail/:id" Component={ArticleDetail} />
        <Route path="/effectif" Component={EffectifMain} />
        <Route path="/calendrier" Component={CalendrierEt} />
        <Route path="/classement" Component={Classement} />
        <Route path="/palmares" Component={Palmares} />
        <Route path="/photos" Component={Photos} />
        <Route path="/photoDetail/:id" Component={photosDetail} />
        <Route path="/podcast" Component={Podcasts} />
        <Route path="/fondation" Component={Fondation} />
        <Route path="/ofktv" Component={OFKtv} />
        <Route path="/videodetail/:id" Component={VideoDetail} />
        <Route path="/academie" Component={AcademieOFK} />
        <Route path="/academy" Component={Academy} />
        <Route path="/clubtour" Component={ClubTour} />
        <Route path="/carrieres" Component={Carrières} />
        <Route path="/philosophieDetail" Component={PhilosophieDetail} />
        <Route path="/coeurDetail" Component={CoeurDetail} />
        <Route path="/etudiantsDetail" Component={EtudiantsDetail} />
        <Route path="/notreFamille" Component={NotreFamille} />
        <Route path="/nousRejoindre" Component={NousRejoindre} />
        <Route path="/Partenaires" Component={Partenaires} />
        <Route path="/presidence" Component={Présidence} />
        <Route path="/boutique" Component={Boutique} />
        <Route path="/maillotsportés" Component={MaillotsPortés} />
      </Routes>
    </>
  );
}

export default App;
